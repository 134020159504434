import Navigation from '../utils/Navigation';
import findAncestor from '../utils/findAncestor';
import app from '../global';
var navigation = Navigation.getInstance();
var instance;
var hidetimeout;

function Footer() {
  this.verbose = false;
}

function init() {
  var wrapper = document.getElementById('footer');
  this.elements = {
    wrapper
  };
  wrapper.addEventListener('click', e => {
    // return;
    console.log('CLICKED FOOTER!');
    var external = findAncestor(e.target, '[target="_blank"]');
    if (external) return;
    e.preventDefault();
    /**
     * Legal button was clicked
     */

    var legal_button = findAncestor(e.target, '.legal_button');

    if (legal_button) {
      wrapper.classList.toggle('open');
      window.clearTimeout(hidetimeout);
      return;
    }

    wrapper.classList.remove('open');
    /**
     * Open button was clicked
     */

    var open_button = findAncestor(e.target, '.open_button');

    if (open_button) {
      open.call(this);
      return;
    }
    /**
     * Close button was clicked
     */


    var close_button = findAncestor(e.target, '.close_button');

    if (close_button) {
      close.call(this);
      return;
    }

    var section_link = findAncestor(e.target, '.section_link');

    if (section_link) {
      close.call(this);
      navigation.changeSection(section_link.dataset.section);
      return;
    }

    var url = e.target.tagName === 'A' ? e.target.getAttribute('href') : false;
    if (url) navigation.changeSection(url);
  });
}

function resize() {
  console.log('resize menu');
}

function open() {
  this.elements.wrapper.classList.remove('close', 'open');
  window.clearTimeout(hidetimeout);
  window.requestAnimationFrame(() => {
    this.elements.wrapper.classList.add('open');
  });
}

function close() {
  this.elements.wrapper.classList.add('close');
}

function show() {
  this.elements.wrapper.classList.add('show');
  hidetimeout = window.setTimeout(() => {
    this.elements.wrapper.classList.remove('open');
  }, 3000);
}

function hide() {
  if (this.verbose) console.log("Main Menu | ".concat(this.menuID, " | hide"));
  this.elements.wrapper.classList.remove('show');
}

Footer.prototype.init = init;
Footer.prototype.hide = hide;
Footer.prototype.show = show;
Footer.prototype.open = open;
Footer.prototype.close = close;
Footer.prototype.resize = resize;
export default {
  getInstance: () => {
    instance = instance || new Footer();
    return instance;
  }
};