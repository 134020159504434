/* eslint-disable class-methods-use-this */

/* eslint-disable max-len */
import { gsap } from 'gsap/all';
import sfx from '../utils/sfx';
import LoaderUI from '../utils/LoaderUI';
import app from '../global';
sfx.loadFX('dialogue');

class UWOMTLoaderUI extends LoaderUI {
  constructor() {
    super();
    this.id = 'UWOMTLoaderUI';
  }

  init(callback) {
    return new Promise(resolve => {
      this.elem = document.querySelector('#loader');
      this.percentage = document.querySelector('#loaderText');
      resolve();
    });
  }

  goOut() {
    return new Promise(resolve => {
      // if (this.elem) this.elem.classList.add('hide');
      if (this.elem) this.elem.classList.add('complete');
      this.elem.querySelector('.enter_button').addEventListener('click', e => {
        sfx.playFx('sx_enter_click_r1', 'siteFX');
        window.requestAnimationFrame(() => {
          sfx.playSound('DX_IM_SO_HAPPY');
        });
        app.background_loop = sfx.playSound('sx_amb_drone_loop_r1');
        resolve();
        this.hide();
      });
    });
  }

  bringIn() {
    return new Promise(resolve => {
      console.log('BRING IN LOADER override'); // if (this.elem) this.elem.classList.add('show');

      resolve();
    });
  }

  show() {
    if (this.elem) this.elem.classList.add('bringin');
  }

  hide() {
    if (this.elem) this.elem.classList.add('hide');
  }

  onProgress(perc) {
    // if (this.prog_el) {
    //   this.prog_el.style.transform = `scaleX(${perc})`;
    // } else {
    //   this.prog_el_fallback.style.width = `${Math.round(perc * 100)}%`;
    // }
    this.percentage.textContent = "".concat(Math.round(perc * 100), "%");
    return perc >= 1;
  }

  resize() {} // this.elem = document.getElementById('loader');
  // this.percentage = document.getElementById('percentage');


}

export default UWOMTLoaderUI;