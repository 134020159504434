/* eslint-disable no-case-declarations */

/* eslint-disable class-methods-use-this */
import { gsap } from 'gsap/all';
import { LoadingManager, Mesh, MeshBasicMaterial, Vector3, Raycaster, PlaneGeometry, Group, ShaderMaterial, Color, DoubleSide, Quaternion, Matrix4, Euler, TextureLoader, AnimationMixer, PointLight, Scene, MeshStandardMaterial, MeshPhongMaterial, VideoTexture, Object3D } from "three";
import { OrbitControls } from '../utils/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as dat from 'dat.gui';
import Interaction from '../utils/Interaction';
import ThreeJSPipelineModule from './ThreeJSPipelineModule';
import PosterTrackerPipelineModule from './PosterTrackerModule';
import getNamedObjects from '../utils/getNamedObjects';
import art_data from './artData';
import app from '../global';
import Navigation from '../utils/Navigation';
import sfx from '../utils/sfx';
import coverFrag from './shaders/cover-frag.glsl';
import coverVert from './shaders/cover-vert.glsl';
var instance;
var modelview = false;
var SCREEN_WIDTH = window.innerWidth;
var SCREEN_HEIGHT = window.innerHeight;
var posterTracked = false;
var destPosterPosition = new Vector3();
var destPosterQuaternion = new Quaternion();
var destPosterScale = new Vector3();
var modelViewerGroup = new Group();
var modelViewerProxy = new Object3D();
var modelViewerScene = new Scene();
modelViewerScene.add(modelViewerGroup);
modelViewerScene.autoUpdate = true;
modelViewerGroup.position.set(0, 0, -3);
modelViewerProxy.position.set(0, 0, -3);
var rotationEuler = new Euler();
var rotationQuaternion = new Quaternion();
var prevTime = 0;
var currTime = 0;
var runTime = 0;
var controls;

var _objects;

var targetRotationX = 0;
var targetRotationY = 0;
var slowingFactor = 0.25;
var mouseXOnMouseDown = 0;
var mouseYOnMouseDown = 0;
var posterFound = false;
var nicsounds = ['DX_YOUR NIC FUCKING CAGE', 'DX_IS THIS SUPPOSED TO BE ME ITS GROTESQUE'];
var giftsounds = ['DX_HOW MUCH DID YOU PAY FOR THIS', 'DX_THIS IS NOT FOR SALE'];
var artsounds = ['DX_HE WAS INCREDIBLE', 'DX_YOU HAVE A GIFT', 'DX_NO ITS NOT CREEPY'];
var headsounds = ['DX_AN ENORMOUS HEAD', 'DX_I HAVE A VERY BIG HEAD', 'DX_IS THIS SUPPOSED TO BE ME'];
var navigation = Navigation.getInstance();
var webglPosterGroup = new Group();
var loadManager = new LoadingManager();
var animationParams = {
  amplitude: 0.1,
  frequency: 3.25,
  ypos: -1.5,
  divypos: 0.424,
  wide: 1,
  tall: 1
};

function initDatGui() {
  var gui = new dat.GUI({
    closed: true
  });
  gui.add(animationParams, 'frequency', 0, 5, 0.0001);
  gui.add(animationParams, 'amplitude', 0, 1, 0.0001);
  gui.add(animationParams, 'ypos', -5, 1, 0.0001);
  gui.add(animationParams, 'divypos', -1, 2, 0.0001);
  gui.add(animationParams, 'wide', 0, 3, 0.0001);
  gui.add(animationParams, 'tall', 0, 3, 0.0001);
  var toggleSettingsBtn = document.createElement('div');
  toggleSettingsBtn.style.position = "absolute";
  toggleSettingsBtn.style.top = "48px";
  toggleSettingsBtn.style.left = "0px";
  toggleSettingsBtn.style.backgroundColor = "red";
  toggleSettingsBtn.style.width = toggleSettingsBtn.style.height = "80px";
  document.body.appendChild(toggleSettingsBtn);
  toggleSettingsBtn.addEventListener('click', () => {
    for (var o in _objects) {
      if (o !== "walls_floor") _objects[o].visible = false;
    }
  });
}

function rotateAroundObjectAxis(object, axis, radians) {
  var rotationMatrix = new Matrix4();
  rotationMatrix.makeRotationAxis(axis.normalize(), radians); // const newMatrix = object.matrix.clone().multiply(rotationMatrix);
  // newMatrix.decompose(object.position, object.rotation, object.scale);

  object.matrix.multiply(rotationMatrix);
  object.rotation.setFromRotationMatrix(object.matrix);
  var {
    x,
    y
  } = object.rotation;
  object.rotation.x = Math.max(-Math.PI * 0.15, Math.min(x, Math.PI * 0.15));
  object.rotation.y = Math.max(-Math.PI * 0.25, Math.min(y, Math.PI * 0.25));
  object.rotation.z = 0;
}

function rotateAroundWorldAxis(object, axis, radians) {
  var rotationMatrix = new Matrix4();
  rotationMatrix.makeRotationAxis(axis.normalize(), radians);
  rotationMatrix.multiply(object.matrix); // pre-multiply

  object.matrix = rotationMatrix;
  object.rotation.setFromRotationMatrix(object.matrix);
}

function hideModelViewer() {
  return new Promise(resolve => {
    if (!modelview) {
      resolve();
      return;
    }

    var wrapper = document.querySelector('#ar');
    wrapper.classList.remove('modelviewer');
    var tl = gsap.timeline({
      onComplete: () => {
        modelViewerGroup.remove(...modelViewerGroup.children);
        this.scene.remove(obj);
        modelview = false;
        wrapper.classList.remove('head');
        resolve();
        if (controls) controls.enableRotate = true;
        modelViewerProxy.position.y = 0;
        modelViewerProxy.rotation.y = 0;
      }
    });
    var {
      position,
      rotation,
      scale
    } = this.resetParams;
    var obj = modelViewerGroup.children[0];

    if (obj.name.match(/^art_/)) {
      sfx.playFx('sx_close_poster_r1');
    } else {
      sfx.playFx('sx_close_bust_r1');
    }

    if (app.qr) {
      tl.to(modelViewerGroup.position, {
        y: -5,
        ease: 'power4.inOut',
        duration: 0.75
      }, 0);
      tl.to(modelViewerGroup.rotation, {
        y: Math.PI * 2,
        ease: 'power4.inOut',
        duration: 0.75
      }, 0);
    } else {
      this.scene.attach(obj);
      tl.to(obj.position, {
        x: position.x,
        y: position.y,
        z: position.z,
        ease: 'power4.inOut',
        duration: 0.75
      }, 0);
      tl.to(obj.rotation, {
        x: rotation.x,
        y: rotation.y,
        z: rotation.z,
        ease: 'power4.inOut',
        duration: 0.75
      }, 0);
    }

    tl.to(obj.scale, {
      x: scale.x,
      y: scale.y,
      z: scale.z,
      ease: 'power4.inOut',
      duration: 0.75
    }, 0);
    tl.fromTo(this.cover.material.uniforms.transition, {
      value: 1
    }, {
      value: 0,
      ease: 'power3.inOut',
      duration: 0.65
    }, 0);
  });
}

function showModelViewer(_ref) {
  var {
    object
  } = _ref;
  return new Promise(resolve => {
    var obj;
    var data;
    var wrapper = document.querySelector('#ar');

    if (object.name.match(/^art_/)) {
      obj = object.clone();
      var arr = object.name.split('_'); // [arr[0], arr[1]].join('_');

      data = art_data[object.name];
      this.cover.material.uniforms.color.value.set(new Color(0xf8227f));
      sfx.playFx('sx_touch_poster_r1');
      sfx.playFx(artsounds[0], 'dialogue');
      artsounds.unshift(artsounds.pop());
    } else {
      switch (object.name) {
        case 'clickable_nick':
          obj = this.objects.nic_main.clone();
          data = {
            titles: [],
            artist: {
              name: '',
              link: ''
            },
            scale: {
              wide: 0.8,
              tall: 0.8
            }
          };
          wrapper.classList.add('head');
          this.cover.material.uniforms.color.value.set(new Color(0x2afefc));
          sfx.playFx('sx_touch_poster_r1');
          sfx.playFx(nicsounds[0], 'dialogue');
          nicsounds.unshift(artsounds.pop());
          break;

        case 'clickable_pillow':
          obj = this.objects.pillow_main.clone();
          data = {
            titles: [],
            artist: {
              name: '',
              link: ''
            },
            scale: {
              wide: 1.5,
              tall: 1.5
            }
          };
          wrapper.classList.add('head');
          this.cover.material.uniforms.color.value.set(new Color(0x2afefc));
          sfx.playFx('sx_touch_poster_r1');
          sfx.playFx(artsounds[0], 'dialogue');
          artsounds.unshift(artsounds.pop());
          break;

        case 'clickable_01':
          obj = this.objects.statue_01_7k.clone();
          data = art_data.head_3;
          wrapper.classList.add('head');
          this.cover.material.uniforms.color.value.set(new Color(0x2afefc));
          sfx.playFx('sx_touch_bust_r1');
          sfx.playFx(headsounds[0], 'dialogue');
          headsounds.unshift(headsounds.pop());
          break;

        case 'clickable_02':
          obj = this.objects.statue_02_7k.clone();
          data = art_data.head_2;
          wrapper.classList.add('head');
          this.cover.material.uniforms.color.value.set(new Color(0x2afefc));
          sfx.playFx('sx_touch_bust_r1');
          sfx.playFx(headsounds[0], 'dialogue');
          headsounds.unshift(headsounds.pop());
          break;

        case 'clickable_03':
          obj = this.objects.statue_03_7k.clone();
          data = art_data.head_1;
          wrapper.classList.add('head');
          this.cover.material.uniforms.color.value.set(new Color(0x2afefc));
          sfx.playFx('sx_touch_bust_r1');
          sfx.playFx(headsounds[0], 'dialogue');
          headsounds.unshift(headsounds.pop());
          break;

        case 'clickable_divine':
          navigation.changeSection('selfie');
          sfx.playFx('sx_menu_wipe_down_r1');
          return;

        case 'clickable_gift':
          navigation.changeSection('giftshop');
          sfx.playFx('sx_menu_wipe_up_r1');
          sfx.playFx(giftsounds[Math.floor(Math.random() * giftsounds.length)], 'dialogue');
          return;

        default:
          return;
      }
    }

    targetRotationX = 0;
    targetRotationY = 0;
    this.camera.updateMatrixWorld();
    this.camera.matrixWorld.decompose(modelViewerScene.position, modelViewerScene.rotation, modelViewerScene.scale);
    modelViewerGroup.position.y = 0;
    modelViewerGroup.rotation.set(0, 0, 0);
    modelview = true;
    window.requestAnimationFrame(() => {
      wrapper.classList.add('modelviewer');
    });
    var titles_el = document.querySelector('#titles');
    var artist_el = document.querySelector('#artist a');
    var artworkby_el = document.querySelector('#art_info .artwork_by');
    var titles = [];

    for (var i = 0; i < data.titles.length; i++) {
      var title = data.titles[i];
      titles.push("<p class=\"masker\"><span class=\"maskee\">".concat(title, "</span></p>"));
    }

    titles_el.innerHTML = titles.join('');

    if (data.artist.name === '') {
      artworkby_el.style.opacity = 0;
    } else {
      artworkby_el.style.opacity = 1;
    }

    artist_el.textContent = data.artist.name;
    artist_el.href = data.artist.link;
    var tl = gsap.timeline({
      onComplete: () => {
        // const closebutton = document.querySelector('#close_model_viewer');
        // closebutton.classList.add('show');
        resolve();
      }
    });
    this.resetParams = {
      position: obj.position.clone(),
      rotation: obj.rotation.clone(),
      scale: obj.scale.clone()
    };

    if (app.qr) {
      obj.position.set(0, -5, 0);
      obj.rotation.set(0, 0, 0);
      modelViewerGroup.add(obj);
    } else {
      modelViewerGroup.attach(obj);
    }

    window.requestAnimationFrame(() => {
      tl.to(obj.position, {
        x: 0,
        y: 0,
        z: 0,
        ease: 'power3.out',
        duration: 1.15
      }, 0);
      tl.to(obj.rotation, {
        x: 0,
        y: Math.PI * 2,
        z: 0,
        ease: 'power3.inOut',
        duration: 1.15
      }, 0);

      if (data.scale) {
        var scale = SCREEN_WIDTH > SCREEN_HEIGHT ? data.scale.wide : data.scale.tall;
        tl.to(obj.scale, {
          x: scale,
          y: scale,
          z: scale,
          ease: 'power3.inOut',
          duration: 1.15
        }, 0);
      }
    }); // modelViewerProxy.add(obj);

    obj.renderOrder = 3;
    this.cover.renderOrder = 2;
    this.cover.material.depthTest = false;
    this.cover.material.depthWrite = false;
    if (obj.material) obj.material.transparent = true;

    if (obj.children.length > 0) {
      for (var _i = 0; _i < obj.children.length; _i++) {
        var element = obj.children[_i];
        element.renderOrder = 3;
        if (element.material) element.material.transparent = true;
        console.log(element);
      }
    }

    tl.fromTo(this.cover.material.uniforms.transition, {
      value: 0
    }, {
      value: 1,
      ease: 'power3.inOut',
      duration: 0.65
    }, 0);
    if (controls) controls.enableRotate = false;
  });
}

function getOpenPosterFunction(gltf) {
  var mixer = new AnimationMixer(gltf.scene);
  var clips = gltf.animations;
  var duration = 0;
  clips.forEach(clip => {
    var action = mixer.clipAction(clip);
    action.clampWhenFinished = true;
    action.play();
    duration = Math.max(clip.duration, duration);
  });
  var animationplayed = false;
  return reset => {
    document.documentElement.classList.remove('findposter');

    if (reset) {
      animationplayed = false;
      return;
    }

    if (animationplayed) return;
    animationplayed = true; // this.scene.visible = true;

    webglPosterGroup.visible = true;
    var playback = {
      time: 0
    };
    var tl = gsap.timeline();
    tl.call(() => {
      sfx.playFx('DX_YOU WANT ME TO LET YOU IN THERE', 'dialogue');
    }, null, 2);
    tl.to(playback, {
      time: duration,
      duration,
      ease: 'none',
      onUpdate: () => {
        mixer.setTime(playback.time); // }, repeat:-1});
      },
      onComplete: () => {
        console.log('COMPLETE');
        mixer.setTime(duration - 0.01);
        window.setTimeout(() => {
          sfx.playFx('DX_IS IT TOO MUCH', 'dialogue');
        }, 500);
      }
    }, 3);
  };
}

class WorldScene {
  constructor() {
    this.initialized = false;
    this.pipelineModule = new ThreeJSPipelineModule();
    this.pipelineModule.onRender = this.onRender.bind(this.pipelineModule);
    this.pipelineModule.onUpdate = this.onUpdate.bind(this.pipelineModule);
    this.settings = {
      pipelineModules: [this.pipelineModule] // cameraFragment: worldCameraFrag

    };
    console.log('APP WORLD SCENE', app.qr);

    if (app.qr) {
      this.posterTrackerPipelineModule = PosterTrackerPipelineModule(this);
      this.posterTrackerPipelineModule.events.subscribe('foundposter', () => {
        console.log('FOUND POSTER', this.shown, posterFound);

        if (this.shown) {
          this.openPoster();
        }

        posterFound = true;
      });
      this.posterTrackerPipelineModule.events.subscribe('lostposter', () => {
        console.log('LOST POSTER'); // document.documentElement.classList.add('findposter');

        posterFound = false;
      });
      this.settings.pipelineModules.push(this.posterTrackerPipelineModule);
    }

    this.pipelineModule.events.subscribe('onStart', () => this.init());
  }

  init() {
    if (app.debug) initDatGui();
    window.requestAnimationFrame(() => {
      document.querySelector('#loader').classList.add('show');
    }, 1000);
    this.sceneVars = this.pipelineModule.getSceneVars(); // if (!app.qr) modelViewerProxy.position.set(0, 0, -3);

    var closebutton = document.querySelector('#close_model_viewer');
    closebutton.addEventListener('click', () => {
      hideModelViewer.call(this).then(() => {
        console.log('MODEL VIEWER IS OUT');
      });
    }); // const loadManager = new LoadingManager();

    this.renderer = this.sceneVars.renderer;
    this.sceneVars.scene.add(webglPosterGroup);
    this.scene = webglPosterGroup;
    window.myscene = this.scene;
    this.camera = this.sceneVars.camera;

    if (app.qr) {
      this.scene.visible = false;
    } else {
      this.camera.position.set(0, 0, 0);
    }

    window.scenecam = this.camera;
    window.scenevars = this.sceneVars;
    window.postergroup = webglPosterGroup;
    this.modelRotationX = 0;
    this.modelRotationY = 0;
    var covergeo = new PlaneGeometry(1, 1, 1, 1);
    var covermat = new ShaderMaterial({
      uniforms: {
        color: {
          value: new Color(0x2afefc)
        },
        opacity: {
          value: 0.85
        },
        transition: {
          value: 0
        }
      },
      fragmentShader: coverFrag,
      vertexShader: coverVert,
      side: DoubleSide,
      transparent: true
    });
    this.cover = new Mesh(covergeo, covermat);
    this.cover.position.z = -1;
    this.camera.add(this.cover);

    this.onUpFn = (x, y, dragInfo) => {
      if (modelview) return;
      console.log('ON UP WORLDSCENE', this.dragging); // navigation.changeSection('modelviewer');

      var mouse = {
        x: x / SCREEN_WIDTH * 2 - 1,
        y: -(y / SCREEN_HEIGHT) * 2 + 1
      };

      if (this.dragging) {
        this.dragging = false;
        return;
      }

      this.dragging = false;
      this.raycaster.setFromCamera(mouse, this.camera);
      var intersects = this.raycaster.intersectObjects(this.clickMeshes, false);

      if (intersects.length > 0) {
        console.log(intersects);
        showModelViewer.call(this, intersects[0]).then(() => {
          console.log('MODEL VIEWER IS IN');
        });
      }
    };

    this.onDownFn = (x, y) => {
      this.dragging = false;
      mouseXOnMouseDown = x - SCREEN_WIDTH * 0.5;
      mouseYOnMouseDown = y - SCREEN_HEIGHT * 0.5;
    };

    this.onDragFn = (x, y, dX, dY) => {
      this.dragging = true;
      targetRotationX += dX * 0.00075 * window.devicePixelRatio; // 1 / SCREEN_WIDTH;

      targetRotationY += dY * 0.00075 * window.devicePixelRatio; // 1 / SCREEN_WIDTH;
    };

    this.interactions = new Interaction({
      element: document.body,
      onDown: (x, y) => {
        if (this.onDownFn) this.onDownFn(x, y);
      },
      onUp: (x, y, dragInfo) => {
        if (this.onUpFn) this.onUpFn(x, y, dragInfo);
      },
      onDrag: (x, y, dX, dY) => {
        if (this.onDragFn) this.onDragFn(x, y, dX, dY);
      } // onMove: (x, y) => { if(this.onMoveFn) this.onMoveFn(x, y)},
      // onKeyDown: (keyCode, arrows) => { if(this.onKeyDownFn) this.onKeyDownFn(keyCode, arrows)},
      // onMouseWheel: (event) => { if(this.onMouseWheelFn) this.onMouseWheelFn(event)}

    });
    this.raycaster = new Raycaster();
    this.pointLight = new PointLight();
    this.pointLight.position.y = 2;
    this.pointLight.position.x = -1;
    this.scene.add(this.pointLight);
    modelViewerScene.add(this.pointLight.clone()); /// LOAD TEXTURES AND CREATE MATERIAL!

    var createMeshBasic = texURL => {
      var texObj = new TextureLoader(loadManager).load(texURL, tex => {
        tex.flipY = false;
      });
      return new MeshBasicMaterial({
        map: texObj
      });
    };

    var createMeshPhong = function createMeshPhong(texURL) {
      var shininess = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 30;
      var texObj = new TextureLoader(loadManager).load(texURL, tex => {
        tex.flipY = false;
      });
      return new MeshPhongMaterial({
        color: 0x000000,
        emissive: 0xFFFFFF,
        emissiveMap: texObj,
        shininess
      });
    };

    var sill_mat = new MeshPhongMaterial({
      color: 0xFFFFFF
    }); // const main_wall_texture = new TextureLoader(loadManager).load(`${app.site_path}assets/images/textures/room_walls_floor_color.jpg`, tex => { tex.flipY = false; });
    // this.main_wall_mat = new MeshBasicMaterial({ map: main_wall_texture });

    var gun_color = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/images/textures/Gun_BaseColor.jpg"), tex => {
      tex.flipY = false;
    });
    var gun_metal = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/images/textures/Gun_Metallic.jpg"), tex => {
      tex.flipY = false;
    });
    var gun_normal = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/images/textures/Gun_Normal.jpg"), tex => {
      tex.flipY = false;
    });
    var gun_roughness = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/images/textures/Gun_Roughness.jpg"), tex => {
      tex.flipY = false;
    });
    this.gun_mat = new MeshStandardMaterial({
      map: gun_color,
      normalMap: gun_normal,
      metalnessMap: gun_metal,
      roughnessMap: gun_roughness
    });
    this.pillow_mat = createMeshPhong("".concat(app.site_path, "assets/images/textures/pillow_pillow_main_color.jpg"), 20);
    this.wax_figure_mat = createMeshPhong("".concat(app.site_path, "assets/images/textures/nic_color.jpg"), 10);
    this.main_wall_mat = createMeshBasic("".concat(app.site_path, "assets/images/textures/room_walls_floor_color.jpg"));
    this.main_wall_mat.map.anisotropy = 2;
    this.poster_mat = createMeshBasic("".concat(app.site_path, "assets/images/textures/poster.jpg"));
    this.props_mat = createMeshBasic("".concat(app.site_path, "assets/images/textures/props_color.jpg"));
    this.wall_art_mat = createMeshPhong("".concat(app.site_path, "assets/images/textures/art_color.jpg"), 20);
    this.hotspot_mat = createMeshBasic("".concat(app.site_path, "assets/images/textures/hotspot.png"));
    this.hotspot_divine_mat = createMeshBasic("".concat(app.site_path, "assets/images/textures/hotspot-divine.png"));
    this.question_mat = createMeshBasic("".concat(app.site_path, "assets/images/textures/question.png"));
    var vid_texture = new VideoTexture(document.getElementById('art_vid'));
    vid_texture.flipY = true; // MeshPhongMaterial

    this.vid_mat = new MeshPhongMaterial({
      color: 0x000000,
      emissive: 0xFFFFFF,
      emissiveMap: vid_texture
    });
    this.statue_01_mat = createMeshPhong("".concat(app.site_path, "assets/images/textures/statue_01_color.jpg"), 12);
    this.statue_02_mat = createMeshPhong("".concat(app.site_path, "assets/images/textures/statue_02_color.jpg"), 12);
    this.statue_03_mat = createMeshPhong("".concat(app.site_path, "assets/images/textures/statue_03_color.jpg"), 12);
    this.text_mat = new MeshBasicMaterial({
      color: 0xf8227f,
      side: DoubleSide
    }); // LOAD SCENE

    new GLTFLoader(loadManager).load("".concat(app.site_path, "assets/models/museum2.glb"), gltf => {
      _objects = this.objects = getNamedObjects(gltf.scene, {}, true);
      if (app.debug) window.sceneObjects = _objects;
      console.log(_objects, gltf.scene);
      this.swapMaterials(gltf.scene);
      _objects.coming_soon1 = _objects.coming_soon001.clone();
      _objects.coming_soon2 = _objects.coming_soon001.clone();

      _objects.coming_soon001.position.set(_objects.statue_01_7k.position.x, _objects.statue_01_7k.position.y - 0.575, _objects.statue_01_7k.position.z);

      _objects.coming_soon1.position.set(_objects.statue_02_7k.position.x, _objects.statue_02_7k.position.y - 0.575, _objects.statue_02_7k.position.z);

      _objects.coming_soon2.position.set(_objects.statue_03_7k.position.x, _objects.statue_03_7k.position.y - 0.575, _objects.statue_03_7k.position.z);

      _objects.coming_soon001.visible = false;
      _objects.coming_soon1.visible = false;
      _objects.coming_soon2.visible = false;
      gltf.scene.add(_objects.coming_soon1, _objects.coming_soon2);
      this.objects.window_sill.material = sill_mat; // set this scale to poster height in pixels (from slices.json>imageInfo) divided by door height in meters (from blender)
      // if (app.qr) gltf.scene.scale.set(783.2560, 783.2560, 783.2560);

      if (app.qr) {
        gltf.scene.scale.setScalar(78.32560);
        XR8.XrController.updateCameraProjectionMatrix({
          cam: {
            nearClipPlane: 1,
            farClipPlane: 3000
          }
        });
      }

      this.clickMeshes = []; // set colorWrite to false and make sure it renders before everything in the scene for occluder material

      var keys = Object.keys(_objects);

      for (var i = 0; i < keys.length; i++) {
        var element = _objects[keys[i]];

        if (keys[i].match(/^art_/)) {
          this.clickMeshes.push(element);
        }

        if (keys[i].match(/clickable_/)) {
          element.visible = false;
          this.clickMeshes.push(element);
        }

        element.renderOrder = 2;
      }

      if (app.qr) {
        this.openPoster = getOpenPosterFunction(gltf);
      } else {
        _objects.left_door.visible = false;
        _objects.right_door.visible = false;
      }

      _objects.poster_wall.renderOrder = 1;
      _objects.poster_wall.material = new MeshBasicMaterial({
        color: 0x00FF00,
        colorWrite: false
      }); // _objects['poster-wall'].visible = false;
      // _objects['left-door'].renderOrder = 0;
      // _objects['right-door'].renderOrder = 0;

      this.scene.add(gltf.scene); // this.scene.visible = false;
      // gltf.scene.add(modelViewerProxy);

      this.camera.add(modelViewerProxy); // window.mygltf = _objects;
      // window.mygltf.scale = gltf.scene.scale;
    });

    loadManager.onLoad = () => {
      window.arscene = this;
      this.initialized = true; // window.addEventListener('resize', this.resize.bind(this));
      // window.addEventListener('onorientationchange', this.resize.bind(this));

      this.resize(); // if (callback) callback();
    };

    if (app.desktop) {
      controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.target = new Vector3(0, 0, -0.5);
      controls.maxAzimuthAngle = Math.PI * 0.24;
      controls.maxPolarAngle = Math.PI * 0.575;
      controls.minAzimuthAngle = -Math.PI * 0.24;
      controls.minPolarAngle = Math.PI * 0.425;
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
    }
  }

  swapMaterials(Scene) {
    var getmaterial = obj => {
      var matname;

      switch (obj.name) {
        case 'statue_01_7k':
          matname = 'statue_01';
          break;

        case 'statue_02_7k':
          matname = 'statue_02';
          break;

        case 'statue_03_7k':
          matname = 'statue_03';
          break;

        default:
          matname = obj.material.name.toLowerCase();
      }

      switch (matname) {
        case 'props':
          return this.props_mat;

        case 'poster':
          return this.poster_mat;

        case 'nic_main':
          return this.wax_figure_mat;

        case 'gun_sdr':
          return this.gun_mat;

        case 'pillow-bake':
          return this.pillow_mat;

        case 'main_wall':
          return this.main_wall_mat;

        case 'wall_art':
          return this.wall_art_mat;

        case 'hotspot':
          return this.hotspot_mat;

        case 'hotspot-divine':
          return this.hotspot_divine_mat;

        case 'question':
          return this.hotspot_mat;

        case 'statue_01':
          return this.statue_01_mat;

        case 'statue_02':
          return this.statue_02_mat;

        case 'statue_03':
          return this.statue_03_mat;

        case 'art-01_video':
        case 'art_02_video':
          return this.vid_mat;

        case 'pink':
          return this.text_mat;

        default:
          return obj.material;
      }
    };

    var checkChildren = obj => {
      var children = obj.children;

      for (var i = 0; i < children.length; i++) {
        var child = children[i];

        if (child.material) {
          child.material = getmaterial(child);
        }

        if (child.materials) for (var m = 0; m < child.materials.length; m++) {
          child.materials[m] = getmaterial(child.materials[m]);
        }
        if (child.children.length) checkChildren(child);
      }
    };

    console.log('Scene', Scene);
    checkChildren(Scene);
  }

  rotateModel(dX, dY) {
    rotationEuler.x = dX;
    rotationEuler.y = dY;
    rotationQuaternion.setFromEuler(rotationEuler); // rotationQuaternion.multiply(this.sceneInner.quaternion);
    // this.sceneInner.quaternion.copy(rotationQuaternion);

    rotationQuaternion.multiply(modelViewerProxy.quaternion);
    modelViewerProxy.quaternion.copy(rotationQuaternion);
  }

  show() {
    this.interactions.addListeners();
    this.resize();
    this.shown = true;

    if (app.qr) {
      if (posterFound) {
        this.openPoster();
      } else {
        // document.documentElement.classList.add('findposter');
        document.documentElement.classList.add('findposter');
      }
    }

    console.log('SHOW THE WORLD SCENE');
  }

  hide() {
    return new Promise(resolve => {
      console.log('HIDE THE MV');
      this.shown = false;
      if (this.posterTrackerPipelineModule) this.posterTrackerPipelineModule.stop();
      this.interactions.removeListeners();
      hideModelViewer.call(this).then(() => resolve());
    });
  }
  /**
   * Apply tracker transformation to webgl and css groups
   * called from PosterTrackerPipelineModule `onUpdate` function
   *
   * @param {Object} transform
   * @param {Number} transform.position
   * @param {Number} transform.quaternion
   * @param {Number} transform.scale
   */


  updateTransform(_ref2) {
    var {
      position,
      quaternion,
      scale
    } = _ref2;
    destPosterPosition.copy(position);
    destPosterQuaternion.copy(quaternion);
    destPosterScale.setScalar(scale * 10); // webglPosterGroup.position.copy(position);
    // webglPosterGroup.quaternion.copy(quaternion);
    // webglPosterGroup.scale.setScalar(scale);
  }

  getWebglPosterGroup() {
    return webglPosterGroup;
  }

  onUpdate(_ref3) {
    var {
      frameStartResult,
      processCpuResult,
      processGpuResult
    } = _ref3;
    var realitySource = processCpuResult.reality || processCpuResult.facecontroller;
    if (!realitySource) return;
    var {
      rotation,
      position,
      intrinsics
    } = realitySource;
    var {
      camera,
      cameraDestPos,
      cameraDestQuat,
      cameraTex,
      renderer
    } = this.sceneVars;
    var {
      cameraTexture
    } = frameStartResult;
    var texProps = renderer.properties.get(cameraTex);
    texProps.__webglTexture = cameraTexture; /////

    for (var i = 0; i < 16; i++) {
      camera.projectionMatrix.elements[i] = intrinsics[i];
    } // Fix for broken raycasting in r103 and higher. Related to:
    //   https://github.com/mrdoob/three.js/pull/15996
    // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
    // the inverse


    if (camera.projectionMatrixInverse) {
      if (camera.projectionMatrixInverse.invert) {
        // THREE 123 preferred version
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
      } else {
        // Backwards compatible version
        camera.projectionMatrixInverse.getInverse(camera.projectionMatrix);
      }
    }

    this.events.publish('onUpdate', {
      rotation,
      position,
      intrinsics
    });

    if (rotation) {
      cameraDestQuat.copy(rotation); // camera.setRotationFromQuaternion(rotation)
    }

    if (position) {
      cameraDestPos.set(position.x, position.y, position.z); // camera.position.set(position.x, position.y, position.z)
    }
  }

  onRender() {
    currTime = Date.now();
    var elapsed = currTime - prevTime;
    runTime += elapsed / 1000;
    prevTime = currTime;
    if (controls) controls.update();
    var {
      scene,
      renderer,
      camera,
      cameraDestPos,
      cameraDestQuat
    } = this.sceneVars;

    if (app.qr) {
      camera.quaternion.copy(cameraDestQuat); // camera.quaternion.slerp(cameraDestQuat, 0.25);

      camera.position.copy(cameraDestPos); // camera.position.lerp(cameraDestPos, 0.25);

      if (destPosterScale.x !== 0) {
        if (posterTracked === false) {
          posterTracked = true;
          webglPosterGroup.position.copy(destPosterPosition);
          webglPosterGroup.quaternion.copy(destPosterQuaternion);
          webglPosterGroup.scale.copy(destPosterScale);
        } else {
          webglPosterGroup.position.lerp(destPosterPosition, 0.25);
          webglPosterGroup.quaternion.slerp(destPosterQuaternion, 0.25);
          webglPosterGroup.scale.lerp(destPosterScale, 0.25);
        }
      }
    } else if (!app.desktop) {
      camera.quaternion.slerp(cameraDestQuat, 0.25); // camera.position.copy(cameraDestPos);
    }

    renderer.clearDepth();
    renderer.render(scene, camera);
    camera.matrixWorld.decompose(modelViewerScene.position, modelViewerScene.rotation, modelViewerScene.scale);
    renderer.clearDepth();
    renderer.render(modelViewerScene, camera);
    if (!this.paused) this.events.publish('render'); // modelViewerProxy.updateMatrix();
    // console.log(modelViewerProxy.rotation);
    // if (modelViewerProxy.rotation.x > Math.PI * 0.25 || modelViewerProxy.rotation.x < -Math.PI * 0.25) targetRotationX = 0;
    // if (modelViewerProxy.rotation.y > Math.PI * 0.25 || modelViewerProxy.rotation.y < -Math.PI * 0.25) targetRotationY = 0;

    rotateAroundObjectAxis(modelViewerGroup, new Vector3(0, 1, 0), targetRotationX);
    rotateAroundObjectAxis(modelViewerGroup, new Vector3(1, 0, 0), targetRotationY);
    targetRotationY *= 1 - slowingFactor;
    targetRotationX *= 1 - slowingFactor;

    if (_objects) {
      _objects.coming_soon001.rotation.y += 0.0005 * elapsed;
      _objects.coming_soon001.position.y = animationParams.ypos + Math.sin(runTime * animationParams.frequency) * animationParams.amplitude;
      _objects.coming_soon1.rotation.y += 0.0005 * elapsed;
      _objects.coming_soon1.position.y = animationParams.ypos + Math.sin(runTime * animationParams.frequency + animationParams.frequency * 0.333) * animationParams.amplitude;
      _objects.coming_soon2.rotation.y += 0.0005 * elapsed;
      _objects.coming_soon2.position.y = animationParams.ypos + Math.sin(runTime * animationParams.frequency + animationParams.frequency * 0.666) * animationParams.amplitude;
      _objects.divine_text.rotation.y -= 0.001 * elapsed;
      _objects.tincture.rotation.y -= 0.001 * elapsed;
      _objects.divine_text.position.y = animationParams.divypos + Math.sin(runTime * animationParams.frequency) * animationParams.amplitude;
    } // console.log(controls);
    // controls.object = camera;
    // if (controls) controls.update();

  }

  resize() {
    SCREEN_WIDTH = window.innerWidth;
    SCREEN_HEIGHT = window.innerHeight; // if (!app.qr) {

    if (app.desktop) {
      this.camera.aspect = SCREEN_WIDTH / SCREEN_HEIGHT;
      this.camera.updateProjectionMatrix(); // this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    }

    document.body.querySelector('#ar').style.height = "".concat(SCREEN_HEIGHT - document.body.querySelector('#footer .bottom_bar').offsetHeight, "px");
    console.log('RESIZING WORLD SCENE');
  }

}

export default {
  getInstance() {
    instance = instance || new WorldScene();
    return instance;
  }

};