import { Vector3 } from 'three';
import * as dat from 'dat.gui';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import ThreeJSPipelineModule from './ThreeJSPipelineModule';
import selfieCameraFrag from './shaders/selfieCamera-frag.glsl';
import app from '../global';
import Kaliedoscope from './Kaliedoscope';
var instance;
var SCREEN_WIDTH = window.innerWidth;
var SCREEN_HEIGHT = window.innerHeight;
var facePosition = {
  x: 0.5,
  y: 0.5
};

class SelfieScene {
  constructor() {
    this.initialized = false;
    window.requestAnimationFrame(() => {
      document.querySelector('#loader').classList.add('show');
    }, 1000);
    this.pipelineModule = new ThreeJSPipelineModule();
    this.kaliedoscope = Kaliedoscope.getInstance();
    this.settings = {
      pipelineModules: [this.pipelineModule, this.kaliedoscope],
      cameraFragment: selfieCameraFrag
    };
    this.pipelineModule.events.subscribe('onStart', () => this.init());
    this.facePos = new Vector3();
    this.pipelineModule.listeners.push({
      event: 'facecontroller.facefound',
      process: this.faceUpdate.bind(this)
    }, {
      event: 'facecontroller.faceupdated',
      process: this.faceUpdate.bind(this)
    });
  }

  faceUpdate(event) {
    var {
      transform,
      attachmentPoints
    } = event.detail;
    this.facePos.copy(transform.position);
    this.facePos.project(this.sceneVars.camera);
    facePosition.x = 0.5 - this.facePos.x / 2;
    facePosition.y = 0.5 - this.facePos.y / 2; // console.log(facePos);
  }

  init() {
    this.initialized = true;
    this.sceneVars = this.pipelineModule.getSceneVars();
    this.kaliedoscope.setThreeJSPipeline(this.pipelineModule); // this.kaliedoscope.watchBow();
    // document.querySelector('#loader').classList.add('show');
  }

  reset() {
    if (this.initialized) {// this.kaliedoscope.reset();
      // this.kaliedoscope.mesh.visible
    }
  }

}

export default {
  getInstance() {
    instance = instance || new SelfieScene();
    return instance;
  }

};