/* eslint-disable no-mixed-operators */

/* eslint-disable prefer-destructuring */

/* eslint-disable class-methods-use-this */
import * as dat from 'dat.gui';
import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import pubsub from '../utils/pubsub';
import app from '../global';
import Navigation from '../utils/Navigation';
import WorldScene from '../widgets/WorldScene'; // import sfx from '../utils/sfx';

var myName = 'AR';
var sectionLoader = SectionLoader.getInstance();
var navigation = Navigation.getInstance();
var instance;

class AR extends Section {
  constructor() {
    super(myName);
  }

  initDatGui() {
    var gui = new dat.GUI({
      closed: true
    });
  } // eslint-disable-next-line class-methods-use-this


  prepareLoad() {
    var files = [// '/assets/images/textures/room_walls_floor_color.png'
    ];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  startPipeline(callback) {
    var worldScene = WorldScene.getInstance();
    app.xrpipeline.startWorld(worldScene.settings);
    if (callback) callback();
  }

  init(callback) {
    console.log('INITAR');

    if (this.initialized) {
      if (callback) callback();
      return;
    }

    if (app.debug || app.noar) this.initDatGui();
    this.events = pubsub.getInstance();
    super.init(callback);
    this.resize();
  }

  update(data) {}

  show(callback) {
    super.show();
    var cameraswitcher = document.querySelector('#cameraswitcher');
    cameraswitcher.classList.remove('selfie');
    document.body.querySelector('#art_vid').play();
    callback();
    var worldScene = WorldScene.getInstance();
    worldScene.show();
    this.resize();

    if (!app.desktop && !app.qr) {// document.body.classList.add('tap_to_place');
    }
  }

  hide(callback) {
    super.hide();
    var worldScene = WorldScene.getInstance();
    worldScene.hide().then(() => {
      document.body.querySelector('#art_vid').pause();
      app.xrpipeline.stop();
      callback();
    });
  }

  resize() {
    this.winWidth = window.innerWidth;
    this.winHeight = window.innerHeight;
    var worldScene = WorldScene.getInstance();
    worldScene.resize();
  }

}

export default {
  getInstance() {
    instance = instance || new AR();
    return instance;
  }

};