function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { NearestFilter } from 'three';
import { BufferAttribute, MeshBasicMaterial, MeshStandardMaterial, DoubleSide, ShaderMaterial, Texture, Vector2, PlaneGeometry, TextureLoader, AnimationMixer, LoadingManager, Color, Mesh, Vector4, LinearFilter, BufferGeometry, MirroredRepeatWrapping, Vector3, Quaternion, Euler, Matrix4, Group, MeshMatcapMaterial } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { gsap } from 'gsap/all';
import pubsub from '../utils/pubsub';
import kaliedoscopeFrag from './shaders/kaliedoscope-frag.glsl';
import kaliedoscopeVert from './shaders/kaliedoscope-vert.glsl';
import app from '../global';
import getNamedObjects from '../utils/getNamedObjects';
import sfx from '../utils/sfx';
var xAxis = new Vector3(1, 0, 0);
var downAxis = new Vector3(0, 1, 0);
var frontAxis = new Vector3(0, 0, -1);
var instance;
var divine_sounds = {
  "A": "sx_ANIM_A_i_am_javi_r1",
  "B": "sx_ANIM_B_i_cant_drive_on_acid_r1",
  "C": "sx_ANIM_C_we_have_to_open_our_minds_r1"
};
var w = 1;
var h = Math.tan(Math.PI / 3) * (w / 2);
var midPointSides = w / 2 / Math.cos(Math.PI / 6);
var scenes = {};
var sceneIds = ["A", "B", "C"];
var currScene = 0;
var materials = {
  pink: new MeshBasicMaterial({
    color: new Color(0xFF2F7F),
    toneMapped: false
  }),
  blue: new MeshBasicMaterial({
    color: new Color(0x0059FE),
    toneMapped: false
  }),
  hands: new MeshBasicMaterial({
    vertexColors: true,
    toneMapped: false
  }),
  model: new MeshStandardMaterial({
    vertexColors: false,
    toneMapped: false
  })
}; //"mouth","eye","nose","center"

var presets = [{
  id: "default",
  scale: 4.5,
  mask: 0.8,
  focalPtLock: 0,
  uvZoom: 0,
  trackingPoint: "head"
}, {
  id: "smallHeads",
  scale: 1.7,
  mask: 0.735,
  focalPtLock: 1,
  uvZoom: 0.0581,
  trackingPoint: "head"
}, {
  id: "teeth",
  scale: 2.9,
  mask: 1,
  focalPtLock: 1,
  uvZoom: 0.7084,
  trackingPoint: "mouth"
}, {
  id: "headmush",
  scale: 2.9,
  mask: 0.6157,
  focalPtLock: 1,
  uvZoom: 0.3182,
  trackingPoint: "eye"
}];
var presetQueue = [];

class Kaliedoscope {
  constructor() {
    _defineProperty(this, "name", "kaliedoscope");

    sfx.loadSound('mx_divine_inspo_bow_one_shot_r1', false);
    sfx.loadSound('mx_divine_inspo_psych_loop_r1');
    sfx.loadSound('sx_ANIM_A_i_am_javi_r1', false);
    sfx.loadSound('sx_ANIM_B_i_cant_drive_on_acid_r1', false);
    sfx.loadSound('sx_ANIM_C_we_have_to_open_our_minds_r1', false);
    this.threeJSPipeline = null;
    this.trackBow = 0;
    this.bowStart = 0;
    this.bowVal = 0;
    this.bowThreshold = 0.25;
    this.facePos = new Vector3();
    this.nosePos = new Vector3();
    this.faceVec = new Vector3();
    this.faceTransform = new Group();
    this.listeners = [{
      event: 'facecontroller.facefound',
      process: this.faceUpdate.bind(this)
    }, {
      event: 'facecontroller.faceupdated',
      process: this.faceUpdate.bind(this)
    }];
    this.trackingPoint = "head";
    this.onAttach = this._onCanvasSizeChange.bind(this);
    this.onStart = this._onCanvasSizeChange.bind(this);
    this.onCanvasSizeChange = this._onCanvasSizeChange.bind(this);
    this.onRender = this._onRender.bind(this);
    this.events = pubsub.getInstance();
    this.geo = new BufferGeometry();
    this.currTime = Date.now();
    this.runTime = 0;
    this.prevTime = Date.now();
    var data = {
      vertices: [],
      verticesSpread: [],
      centerPts: [],
      rotationDirs: [],
      uvs: []
    };
    var diameter = 15;
    var numCols = 1 + 2 * Math.ceil(diameter / w);
    var numRows = Math.ceil(diameter / h); // since we want the center to be a triangle then both the cols and rows need to be odd
    // numCols is already going to be odd, but we'll add one to numRows if it isn't already

    numRows += numRows & 1 ? 0 : 1; // numRows = 7;
    // numCols = 11;

    var colFlip = (numCols - 1) / 2 & 1;
    var rowFlip = (numRows - 1) / 2 & 1;
    console.log("colFlip", colFlip, "rowFlip", rowFlip);
    var middleIndex = (numCols - 1) / 2;
    var rotationsAtMiddle = Math.floor(middleIndex / 2) % 3;
    var rotOffset = 3 - rotationsAtMiddle; // numRows = 3;

    var startX = -((numCols - 1) / 2) * w / 2; // const startY = -(numRows/2) * h;

    var startY = -((numRows - 1) / 2) * h;
    console.log(numCols, numRows);

    for (var y = 0; y < numRows; y++) {
      for (var x = 0; x < numCols; x++) {
        var isDown = x + colFlip + (y + rowFlip & 1) & 1 ? true : false;
        var oddRow = y + rowFlip & 1 ? true : false;
        var rotations = 0; // if(oddRow)rotations += 1;

        rotations = 30 + Math.ceil((x - middleIndex) / 2);
        if (oddRow && !isDown) rotations += 1;
        if (!oddRow && isDown) rotations += 1;
        var uvFlip = oddRow && isDown;
        if (!oddRow && isDown) uvFlip = true; // let uvFlip = (oddRow && isDown) || (!oddRow && !isDown);

        var buildFn = isDown ? this.createPointingDown.bind(this) : this.createPointingUp.bind(this);
        buildFn(startX + x * (w / 2), startY + y * h, rotations, uvFlip, data);
      }
    }

    this.createSpreadVertices(data); // at this point the data object should be filled with all of the
    // triangle data.

    this.geo = new BufferGeometry();
    this.geo.setAttribute('position', new BufferAttribute(new Float32Array(data.vertices), 3));
    this.geo.setAttribute('positionSpread', new BufferAttribute(new Float32Array(data.verticesSpread), 3));
    this.geo.setAttribute('centerPt', new BufferAttribute(new Float32Array(data.centerPts), 2));
    this.geo.setAttribute('uv', new BufferAttribute(new Float32Array(data.uvs), 2));
    this.geo.setAttribute('rotationDir', new BufferAttribute(new Float32Array(data.rotationDirs), 1));
    this.handsTex = new TextureLoader().load("".concat(app.site_path, "assets/images/textures/handsMatcap.jpg"));
    this.handsMatCapMat = new MeshMatcapMaterial({
      matcap: this.handsTex
    });
    this.material2 = new MeshBasicMaterial({
      color: new Color(0xFF0000),
      side: DoubleSide
    });
    this.texture = new Texture(); // this.texture.minFilter = LinearFilter;
    // this.texture.magFilter = LinearFilter;
    // this.texture.minFilter = NearestFilter;
    // this.texture.magFilter = NearestFilter;

    this.texture.wrapS = MirroredRepeatWrapping;
    this.texture.wrapT = MirroredRepeatWrapping;
    this.material = new ShaderMaterial({
      uniforms: {
        map: {
          value: this.texture
        },
        resolution: {
          value: new Vector2(window.innerWidth, window.innerHeight)
        },
        mask: {
          value: 0
        },
        focalPt: {
          value: new Vector2(0, 0)
        },
        focalPtLock: {
          value: 0
        },
        uvRotation: {
          value: 0
        },
        uvZoom: {
          value: 0
        },
        uvRatio: {
          value: 1
        },
        variation: {
          value: 1
        },
        spread: {
          value: 0
        },
        cropBox: {
          value: new Vector4(0, 0, 1, 1)
        }
      },
      fragmentShader: kaliedoscopeFrag,
      vertexShader: kaliedoscopeVert,
      side: DoubleSide
    });
    this.mesh = new Mesh(this.geo, this.material);
    this.mesh.scale.setScalar(2.5); // this.mesh = new Mesh(new PlaneGeometry(diameter*(window.innerWidth/window.innerHeight), diameter, 1, 1), this.material2);
  }

  createPointingUp(currX, currY, _rotate, uvFlip, data) {
    // console.log('createPointingUp',currX, currY);
    var originY = currY + h / 2 - midPointSides;
    var midMinus = h - midPointSides;
    data.vertices.push(0, midPointSides, 0, -(w / 2), -midMinus, 0, +(w / 2), -midMinus, 0);
    var uvs = [0.5, 0, 1, 1, 0, 1];
    if (uvFlip) uvs = [0.5, 0, 0, 1, 1, 1];
    var rotNum = _rotate % 3;

    while (rotNum--) {
      uvs.push(uvs.shift());
      uvs.push(uvs.shift());
    }

    data.uvs.push(...uvs);
    data.centerPts.push(currX, originY, currX, originY, currX, originY);
    var rotDir = uvFlip ? 1 : -1;
    data.rotationDirs.push(rotDir, rotDir, rotDir);
  }

  createPointingDown(currX, currY, _rotate, uvFlip, data) {
    // console.log('createPointingDown',currX, currY);
    var originY = currY - h / 2 + midPointSides;
    var midMinus = h - midPointSides;
    data.vertices.push(0, -midPointSides, 0, +(w / 2), midMinus, 0, -(w / 2), midMinus, 0);
    var uvs = [0.5, 0, 1, 1, 0, 1];
    if (uvFlip) uvs = [0.5, 0, 0, 1, 1, 1];
    var rotNum = _rotate % 3;

    while (rotNum--) {
      uvs.unshift(uvs.pop());
      uvs.unshift(uvs.pop());
    }

    data.uvs.push(...uvs);
    data.centerPts.push(currX, originY, currX, originY, currX, originY);
    var rotDir = uvFlip ? 1 : -1;
    data.rotationDirs.push(rotDir, rotDir, rotDir);
  }

  createSpreadVertices(data) {
    // loop for each face
    var numPts = data.vertices.length / 3;
    var numFaces = numPts / 3;
    var ptIndex = 0;
    var vertex = new Vector3();
    var faceDirection = new Euler();
    var facePosition = new Vector3();
    var faceRotation = new Euler();
    var faceTranslationMatrix = new Matrix4();
    var faceMatrix = new Matrix4();

    for (var face = 0; face < numFaces; face++) {
      faceDirection.set(Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2); // set face position

      facePosition.set(1, 0, 0).applyEuler(faceDirection).multiplyScalar(Math.random() * 10);
      facePosition.set(0, 0, 0); // set face rotation

      faceRotation.set((0.5 - Math.random()) * 2 * Math.PI, (0.5 - Math.random()) * 2 * Math.PI, (0.5 - Math.random()) * 2 * Math.PI); // set face matrix from position and rotation

      faceTranslationMatrix.makeTranslation(facePosition.x, facePosition.y, facePosition.z);
      faceMatrix.makeRotationFromEuler(faceRotation);
      faceMatrix.multiply(faceTranslationMatrix);

      for (var pt = 0; pt < 3; pt++) {
        // apply to each pt of face
        ptIndex = face * 9 + pt * 3;
        vertex.set(data.vertices[ptIndex], data.vertices[ptIndex + 1], data.vertices[ptIndex + 2]);
        vertex.applyMatrix4(faceMatrix);
        data.verticesSpread.push(vertex.x, vertex.y, vertex.z);
      }
    }
  }

  show() {
    // we should set everything to default
    this.material.uniforms.mask.value = 0;
    this.trackingPoint = "head";
    this.material.uniforms.focalPtLock.value = 0;
    this.material.uniforms.uvZoom.value = 0;
    this.material.uniforms.uvRatio.value = 1;
    this.material.uniforms.spread.value = 0;
  }

  hide() {
    // we need to stop any animations
    if (this.tl) this.tl.kill();
  }

  setTexture(texObj) {
    this.material.uniforms.map.value = texObj;
    texObj.wrapS = MirroredRepeatWrapping;
    texObj.wrapT = MirroredRepeatWrapping;
    texObj.minFilter = LinearFilter;
    window.texObj = texObj;
    console.log("MirroredRepeatWrapping", MirroredRepeatWrapping);
  }

  _onRender() {
    this.currTime = Date.now();
    var elapsed = this.currTime - this.prevTime;
    this.runTime += elapsed / 1000;
    this.prevTime = this.currTime;
    this.prevTime = this.currTime;
    this.material.uniforms.variation.value = Math.sin(this.runTime);
    this.rotate(elapsed / 1000);
  }

  rotate(deltaTime) {
    this.mesh.rotation.z += deltaTime / 3;
    this.material.uniforms.uvRotation.value -= deltaTime / 3; // this.material.uniforms.uvRotation.value = Math.PI/2;
  }

  _onCanvasSizeChange(_ref) {
    var {
      canvasWidth,
      canvasHeight,
      videoWidth,
      videoHeight
    } = _ref;
    // console.log(canvasWidth, canvasHeight, videoWidth, videoHeight);
    this.material.uniforms.resolution.value.set(canvasWidth, canvasHeight);
    var _w = 1;
    var _h = 1;

    if (videoWidth / videoHeight > canvasWidth / canvasHeight) {
      // we should crop width
      var vidRatio = videoWidth / videoHeight;
      var canvasRatio = canvasWidth / canvasHeight;
      _w = 1 - (vidRatio - canvasRatio) / vidRatio;
    } else {
      // we should crop height
      var vidRatio = videoHeight / videoWidth;
      var canvasRatio = canvasHeight / canvasWidth;
      _h = 1 - (vidRatio - canvasRatio) / vidRatio;
    }

    this.material.uniforms.cropBox.value.set((1 - _w) / 2, (1 - _h) / 2, 1 - (1 - _w) / 2, 1 - (1 - _h) / 2);
    this.material.uniforms.uvRatio.value = videoWidth / videoHeight;
  }

  _onProcessCpu(_ref2) {
    var {
      processGpuResult
    } = _ref2;
    var {
      viewport,
      shader
    } = processGpuResult.gltexturerenderer;

    if (!viewport) {
      return;
    }

    var {
      width,
      height,
      offsetX,
      offsetY
    } = viewport;
  }

  watchBow() {
    this.bowStart = this.bowVal;
    this.trackBow = 1;
    this.material.uniforms.mask.value = 0;
  }

  bowAccepted() {
    this.events.publish('bowaccepted');
    sfx.playSound('mx_divine_inspo_bow_one_shot_r1');
    sfx.fadeInSound('mx_divine_inspo_psych_loop_r1');
    window.setTimeout(() => {
      sfx.playFx('DX_TRIPPY', 'dialogue');
    }, 1500);
    this.tl = gsap.timeline({
      onComplete: () => {
        this.beginCycle();
      }
    });
    this.tl.to(this.material.uniforms.mask, {
      value: 0.04,
      duration: 1,
      ease: 'power4.inout'
    });
    this.tl.to(this.material.uniforms.mask, {
      value: 0.3,
      duration: 1.5,
      delay: 2,
      ease: 'power4.in'
    });
    this.tl.to(this.material.uniforms.mask, {
      value: 0.5,
      duration: 1.5,
      ease: 'power2.out'
    });
    this.tl.to(this.material.uniforms.mask, {
      value: 0.8,
      duration: 4,
      ease: 'power4.inout'
    });
    this.tl.fromTo(this.mesh.scale, {
      x: 2.5,
      y: 2.5,
      z: 2.5
    }, {
      x: 4.5,
      y: 4.5,
      z: 4.5,
      duration: 4,
      ease: 'none'
    }, 6);
  }

  beginCycle() {
    this.wait(4).then(() => this.createScene(this.getNextScene())).then(() => this.openUp()).then(() => this.playScene()).then(() => this.close()).then(() => this.hideScene()).then(() => this.beginCycle());
  }

  wait(duration) {
    return new Promise((resolve, reject) => {
      // console.log('wait!', duration);
      var waitObj = {
        time: 0
      };
      this.tl = gsap.timeline({
        onComplete: () => {
          resolve();
        }
      });
      this.tl.to(waitObj, {
        time: duration,
        duration: duration,
        ease: "none"
      });
    });
  }

  getNextScene() {
    var id = sceneIds[currScene];
    currScene = (currScene + 1) % sceneIds.length;
    return id;
  }

  shuffleArr(passedArr) {
    var currentIndex = passedArr.length,
        randomIndex; // While there remain elements to shuffle.

    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--; // And swap it with the current element.

      [passedArr[currentIndex], passedArr[randomIndex]] = [passedArr[randomIndex], passedArr[currentIndex]];
    }

    return passedArr;
  }

  getNextPreset() {
    if (!presetQueue.length) {
      for (var p = 0; p < presets.length; p++) {
        presetQueue.push(p);
      }

      presetQueue = this.shuffleArr(presetQueue);
    } // console.log(presetQueue);


    return presets[presetQueue.shift()];
  }

  createScene(id) {
    return new Promise((resolve, reject) => {
      // console.log('createScene!');
      if (scenes[id]) {
        // scene is already created, just return it
        this.sceneObj = scenes[id];
        resolve(scenes[id]);
      } else {
        // the scene needs to be loaded and created
        var loadManager = new LoadingManager(); // loadManager.onLoad = () => {}

        new GLTFLoader(loadManager).load("".concat(app.site_path, "assets/models/divine-") + id + ".glb", gltf => {
          var objects = getNamedObjects(gltf.scene, {}, true);
          this.swapMaterials(gltf.scene);
          gltf.scene.position.z = -20;
          gltf.scene.scale.setScalar(10);
          if (id !== "A") gltf.scene.scale.setScalar(7);
          var mixer = new AnimationMixer(gltf.scene);
          var clips = gltf.animations;
          var duration = 0;
          clips.forEach(clip => {
            var action = mixer.clipAction(clip);
            action.clampWhenFinished = true;
            action.play();
            duration = Math.max(clip.duration, duration);
          });
          scenes[id] = {
            gltf: gltf,
            scene: gltf.scene,
            objects: objects,
            mixer: mixer,
            duration,
            soundId: divine_sounds[id]
          };
          this.sceneObj = scenes[id];
          resolve(scenes[id]);
        });
      }
    });
  }

  openUp() {
    return new Promise((resolve, reject) => {
      // console.log('open up!');
      var sceneVars = this.threeJSPipeline.getSceneVars();
      sceneVars.camera.add(this.sceneObj.scene);
      this.tl = gsap.timeline({
        onComplete: () => {
          resolve();
        }
      });
      this.tl.call(() => {
        sfx.playFx('sx_kaleidoscope_open_r1');
      }, {}, 1.5);
      this.tl.to(this.material.uniforms.mask, {
        value: 0.3,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
      this.tl.to(this.material.uniforms.uvZoom, {
        value: -1,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
      this.tl.to(this.material.uniforms.spread, {
        value: 1,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
      this.tl.to(this.material.uniforms.focalPtLock, {
        value: 0,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
      this.tl.to(this.mesh.scale, {
        x: 1.7,
        y: 1.7,
        z: 1.7,
        duration: 4,
        ease: "elastic.inOut(1.25, 0.5)"
      }, 0);
    });
  }

  playScene() {
    return new Promise((resolve, reject) => {
      // console.log('playScene!');
      var sceneVars = this.threeJSPipeline.getSceneVars();
      var playback = {
        time: 0
      };
      this.tl = gsap.timeline({
        onUpdate: () => {
          this.sceneObj.mixer.setTime(playback.time);
        },
        onComplete: () => {
          resolve();
        }
      });
      this.tl.to(playback, {
        time: this.sceneObj.duration,
        duration: this.sceneObj.duration,
        ease: "none"
      });
      sfx.playSound(this.sceneObj.soundId);
    });
  }

  close() {
    return new Promise((resolve, reject) => {
      // console.log('close!');
      var nextPreset = this.getNextPreset(); // console.log(nextPreset);
      // sfx.playFx('sx_kaleidoscope_close_r1');

      this.tl = gsap.timeline({
        onComplete: () => {
          resolve();
        }
      });
      this.tl.call(() => {
        sfx.playFx('sx_kaleidoscope_close_r1');
      }, {}, 1.5);
      this.tl.to(this.material.uniforms.mask, {
        value: nextPreset.mask,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
      this.tl.to(this.material.uniforms.focalPtLock, {
        value: nextPreset.focalPtLock,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
      this.tl.to(this.material.uniforms.uvZoom, {
        value: nextPreset.uvZoom,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
      this.trackingPoint = nextPreset.trackingPoint;
      this.tl.to(this.mesh.scale, {
        x: nextPreset.scale,
        y: nextPreset.scale,
        z: nextPreset.scale,
        duration: 4,
        ease: "elastic.inOut(1.25, 0.5)"
      }, 0);
      this.tl.to(this.material.uniforms.spread, {
        value: 0,
        duration: 1,
        ease: 'power4.inout'
      }, 1.5);
    });
  }

  hideScene() {
    return new Promise((resolve, reject) => {
      // console.log('hideScene!');
      var sceneVars = this.threeJSPipeline.getSceneVars();
      sceneVars.camera.remove(this.sceneObj.scene);
      resolve();
    });
  }

  setThreeJSPipeline(_threeJSPipeline) {
    this.threeJSPipeline = _threeJSPipeline;
    var sceneVars = this.threeJSPipeline.getSceneVars();
    this.setTexture(sceneVars.cameraTex);
    sceneVars.scene.background = new Color(0xFFEA40);
    sceneVars.camera.add(this.mesh);
    this.mesh.position.z = -10;
  }

  faceUpdate(event) {
    var {
      transform,
      attachmentPoints
    } = event.detail; // console.log(attachmentPoints);

    this.faceTransform.position.copy(transform.position);
    this.faceTransform.quaternion.copy(transform.rotation);
    this.faceTransform.scale.setScalar(transform.scale);
    this.faceTransform.updateMatrix();

    switch (this.trackingPoint) {
      case "mouth":
        this.facePos.copy(attachmentPoints.mouth.position).applyMatrix4(this.faceTransform.matrix);
        break;

      case "eye":
        this.facePos.copy(attachmentPoints.leftEye.position).applyMatrix4(this.faceTransform.matrix);
        break;

      case "nose":
        this.facePos.copy(attachmentPoints.noseTip.position).applyMatrix4(this.faceTransform.matrix);
        break;

      default:
        this.facePos.copy(transform.position);
    } // this.facePos.copy(transform.position);


    this.faceVec.set(0, 0, 1).applyQuaternion(transform.rotation);
    this.bowVal = this.faceVec.dot(downAxis);

    if (this.trackBow) {
      // console.log(this.bowVal, this.bowVal - this.bowStart);
      if (this.trackBow == 1) {
        // Watch downward threshhold
        var bowAmt = this.bowVal - this.bowStart;
        this.bowStart = Math.min(this.bowStart, this.bowVal);

        if (bowAmt > this.bowThreshold) {
          console.log('downward Threshold!');
          this.trackBow = 2;
          this.bowStart = this.bowVal;
        }
      } else if (this.trackBow == 2) {
        // Watch downward threshhold
        var _bowAmt = this.bowStart - this.bowVal;

        this.bowStart = Math.max(this.bowStart, this.bowVal);

        if (_bowAmt > this.bowThreshold) {
          console.log('upward Threshold!');
          this.bowAccepted();
          this.trackBow = 0;
          this.bowStart = this.bowVal;
        }
      }
    } // console.log(this.faceVec.dot(downAxis));
    // console.log(this.nosePos);


    if (this.threeJSPipeline) {
      var sceneVars = this.threeJSPipeline.getSceneVars();
      this.facePos.project(sceneVars.camera);
      this.material.uniforms.focalPt.value.set(this.facePos.x / 2, this.facePos.y / 2);
    } // console.log(facePos);

  }

  swapMaterials(Scene) {
    var getmaterial = material => {
      switch (material.name.toLowerCase()) {
        case 'pink':
          return materials.pink;

        case 'blue':
          return materials.blue;

        case 'head':
        case 'hands':
        case 'bottle':
        case 'model':
          return this.handsMatCapMat;

        default:
          return material;
      }
    };

    var checkChildren = obj => {
      var children = obj.children;

      for (var i = 0; i < children.length; i++) {
        var child = children[i];

        if (child.material) {
          child.material = getmaterial(child.material);
        }

        if (child.materials) for (var m = 0; m < child.materials.length; m++) {
          child.materials[m] = getmaterial(child.materials[m]);
        }
        if (child.children.length) checkChildren(child);
      }
    }; // console.log('Scene', Scene);


    checkChildren(Scene);
  }

}

export default {
  getInstance() {
    instance = instance || new Kaliedoscope();
    return instance;
  }

};