var art_data = {
  head_1: {
    titles: ['April 15-17: 3rd Street Promenade, in front of the Apple store, Los Angeles', 'April 20-24: AMC The Grove 14, 189 The Grove Dr, Los Angeles'],
    artist: {
      name: 'Jules Muck',
      link: 'https://www.instagram.com/muckrock/'
    },
    scale: {
      wide: 0.75,
      tall: 0.9
    }
  },
  head_2: {
    titles: ['April 15-16: Miami Midtown Shopping Mall, Miami', 'April 17-24: Silverspot Theaters, 300 SE 3rd Street, Miami'],
    artist: {
      name: 'David Lavernia',
      link: 'https://www.instagram.com/davel_art/'
    },
    scale: {
      wide: 0.75,
      tall: 0.9
    }
  },
  head_3: {
    titles: ['April 15-16: Occidental Square, Seattle', 'April 17-24: Regal Meridian, 1501 7th Ave, Seattle'],
    artist: {
      name: 'Nikita Ares',
      link: 'https://www.instagram.com/kita.licious/'
    },
    scale: {
      wide: 0.75,
      tall: 0.9
    }
  },
  art_02_still: {
    titles: [// 'Comic Book'
    ],
    scale: {
      wide: 2,
      tall: 1.75
    },
    artist: {
      name: '@thebutcherbilly',
      link: 'https://www.instagram.com/p/Ca25boKPKEu/?utm_source=ig_web_copy_link'
    }
  },
  art_03_still: {
    titles: [// 'Bug'
    ],
    scale: {
      wide: 2,
      tall: 1.75
    },
    artist: {
      name: '@richardwilkinsonart',
      link: 'https://www.instagram.com/p/CbX3bMLqikR/?utm_source=ig_web_copy_link'
    }
  },
  art_01_video_front: {
    titles: [// 'Meditation'
    ],
    scale: {
      wide: 1.75,
      tall: 1.75
    },
    artist: {
      name: '@joeparente34',
      link: 'https://www.instagram.com/tv/Cb7sQXGg0sQ/?utm_source=ig_web_copy_link'
    }
  },
  art_01_still: {
    titles: [// 'Illustrated sunset'
    ],
    scale: {
      wide: 1.1,
      tall: 1.5
    },
    artist: {
      name: '@johnguydo',
      link: 'https://www.instagram.com/p/CcDyOsvOlPg/?utm_source=ig_web_copy_link'
    }
  },
  'art_02_video-front': {
    titles: [// 'Face cycle'
    ],
    scale: {
      wide: 1.75,
      tall: 1.6
    },
    artist: {
      name: '@kidmograph',
      link: 'https://www.instagram.com/tv/CcTVuZBJmu0/?utm_source=ig_web_copy_link'
    }
  },
  art_04_still: {
    titles: [// 'Weird painted head with extra faces'
    ],
    scale: {
      wide: 1.4,
      tall: 1.6
    },
    artist: {
      name: '@andrewcadima',
      link: 'https://www.instagram.com/andrewcadima/'
    }
  }
};
export default art_data;