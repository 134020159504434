import WorldScene from './WorldScene';
import SelfieScene from './SelfieScene';
import Navigation from '../utils/Navigation';
import findAncestor from '../utils/findAncestor';
import app from '../global';
var navigation = Navigation.getInstance();

function Menu() {
  this.verbose = false;
}

function init() {
  if (this.verbose) console.log("Main Menu | ".concat(this.menuID, " | init"));
  var wrapper = document.getElementById('mainHeader');
  this.elements = {
    wrapper
  };
  wrapper.addEventListener('click', e => {
    // return;
    console.log('CLICKED MENU!');
    var cameraswitcher = findAncestor(e.target, '#cameraswitcher');
    if (document.body.dataset.section === 'Selfie') cameraswitcher.classList.add('selfie');

    if (cameraswitcher) {
      e.preventDefault();

      if (cameraswitcher.classList.contains('selfie')) {
        cameraswitcher.classList.remove('selfie'); // const worldScene = WorldScene.getInstance();
        // app.xrpipeline.startWorld(worldScene.settings);

        navigation.changeSection('ar');
      } else {
        cameraswitcher.classList.add('selfie'); // const selfieScene = SelfieScene.getInstance();
        // selfieScene.reset();
        // app.xrpipeline.startSelfie(selfieScene.settings);

        navigation.changeSection('selfie');
      }

      return;
    }
    /**
     * Open button was clicked
     */


    var open_button = findAncestor(e.target, '.open_button');

    if (open_button) {
      e.preventDefault();
      open.call(this);
      return;
    }
    /**
     * Close button was clicked
     */


    var close_button = findAncestor(e.target, '.close_button');

    if (close_button) {
      e.preventDefault();
      close.call(this);
      return;
    }

    var section_link = findAncestor(e.target, '.section_link');

    if (section_link) {
      e.preventDefault();
      close.call(this);
      navigation.changeSection(section_link.dataset.section);
      return;
    }

    var url = e.target.tagName === 'A' ? e.target.getAttribute('href') : false;
    if (url) navigation.changeSection(url);
  });
}

function selectMenuItem(section_name) {
  console.log('section_name', section_name);
  var item = document.body.querySelector("[href^=\"".concat(window.location.pathname, "\"]"));
  var selecteds = document.querySelectorAll('.selected');

  for (var i = 0; i < selecteds.length; i++) {
    var element = selecteds[i];
    element.classList.remove('selected');
  }

  if (item) item.classList.add('selected');
  console.log(item, "Main Menu | ".concat(this.menuID, " | selectMenuItem: ").concat(section_name));
}

function resize() {
  console.log('resize menu');
}

function open() {
  this.elements.wrapper.classList.remove('close', 'open');
  window.requestAnimationFrame(() => {
    this.elements.wrapper.classList.add('open');
  });
}

function close() {
  this.elements.wrapper.classList.add('close');
}

function show() {
  if (this.verbose) console.log("Main Menu | ".concat(this.menuID, " | show"));
  this.elements.wrapper.classList.add('show');
}

function hide() {
  if (this.verbose) console.log("Main Menu | ".concat(this.menuID, " | hide"));
  this.elements.wrapper.classList.remove('show');
}

Menu.prototype.init = init;
Menu.prototype.hide = hide;
Menu.prototype.show = show;
Menu.prototype.open = open;
Menu.prototype.close = close;
Menu.prototype.resize = resize;
Menu.prototype.selectMenuItem = selectMenuItem;
export default {
  getNew: data => new Menu(data)
};