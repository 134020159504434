/* eslint-disable class-methods-use-this */
import SectionLoader from './SectionLoader';
import app from '../global';
var sectionLoader = SectionLoader.getInstance();
var curr_loaderID = false;
var perc = 0;
var instance;
var raf;
var images_to_load;
var images_loaded = 0;
var complete_callback;

class Preloader {
  constructor() {
    this.loaderUIObjects = {};
    sectionLoader.addLoaderUI(this);
  }
  /**
   * Sets loader ui object
   *
   * @param  {Object} loaderUI
   * @param  {Function} callback
   */


  setLoader(loaderUI, callback) {
    curr_loaderID = loaderUI.id;
    this.loaderUIObjects[curr_loaderID] = loaderUI;
    if (callback) callback();
  }
  /**
   * Call bringIn function on current loaderUI object if exists
   * else animate it in using a default tween
   */


  bringIn(callback) {
    return new Promise(resolve => {
      perc = 0;
      this.finished = false;
      var loaderUI = this.loaderUIObjects[curr_loaderID];
      loaderUI.bringIn().then(() => {
        console.log('BRING IN');
        resolve();
        this.startTracking().then(() => {
          console.log('tracking complete');
          if (callback) callback();
        });
      });
    });
  }
  /**
   * Start loop that tracks load progress
   */


  startTracking() {
    return new Promise(resolve => {
      var loaderUI = this.loaderUIObjects[curr_loaderID];
      /**
       * Get percentage, pass it to loaderUI
       */

      var track = () => {
        // console.log('tracking');
        raf = window.requestAnimationFrame(track);
        var newPerc = sectionLoader.getPerc();
        if (Number.isNaN(newPerc) || !Number.isFinite(newPerc)) newPerc = 1;
        perc += Math.ceil(10 * (newPerc - perc) / 0.2) / 1000;
        loaderUI.onProgress(perc);

        if (perc >= 1) {
          window.cancelAnimationFrame(raf);
          this.goOut().then(() => this.isOut(resolve));
        }
      };

      if (app.debug) {
        this.goOut().then(() => this.isOut(resolve));
      } else {
        raf = window.requestAnimationFrame(track);
      }
    });
  }
  /**
   * Animate out loader
   */


  goOut() {
    return new Promise(resolve => {
      this.loaderUIObjects[curr_loaderID].goOut().then(() => resolve());
    });
  }
  /**
   * Called when loader has finished animating out
   */


  isOut(callback) {
    console.log('IS OUT');
    if (complete_callback) complete_callback();
    if (callback) callback();
  }

  load(images, callback) {
    console.log('LOAD', images, callback);
    images_loaded = 0;
    images_to_load = images;

    if (images_to_load.length === 0) {
      callback();
      return;
    }

    var onload = () => {
      images_loaded++;
    };

    for (var i = 0; i < images_to_load.length; i++) {
      var src = images_to_load[i];
      var img = new Image();
      img.addEventListener('load', onload);
      img.src = src;
    }

    this.bringIn(callback); // callback();
  }

  complete(callback) {
    complete_callback = callback || false;
    this.finished = true;
  }

}

export default {
  getInstance: () => {
    instance = instance || new Preloader();
    return instance;
  }
};