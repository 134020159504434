function getNamedObjects(parent, obj) {
  var results = obj || {};

  for (var i = 0; i < parent.children.length; i++) {
    var element = parent.children[i];
    results[parent.children[i].name] = parent.children[i];
    if (element.children.length) getNamedObjects(element, results);
  }

  return results;
}

export default getNamedObjects;