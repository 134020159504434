import pubsub from '../utils/pubsub';
import FullWindowCanvasFactory from './FullWindowPipeline';
var instance;

var arCompatible = () => XR8.XrDevice.isDeviceBrowserCompatible({
  allowedDevices: XR8.XrConfig.device().MOBILE
});

var basicCameraFrag = 'precision mediump float; varying vec2 texUv; uniform sampler2D sampler; void main() {gl_FragColor = texture2D(sampler, texUv);}';
var basicWorldVert = ' attribute vec3 position; attribute vec2 uv; varying vec2 texUv; void main() { gl_Position = vec4(position, 1.0); texUv = uv;}';
var basicSelfieVert = 'attribute vec3 position; attribute vec2 uv; varying vec2 texUv; void main() { gl_Position = vec4(position, 1.0); texUv = vec2(1.0 - uv.x, uv.y);}';
var hasasked = false;

class XRPipeline {
  constructor() {
    this.events = pubsub.getInstance();
  }

  static loadXR8() {
    return new Promise((resolve, reject) => {
      if (typeof XR8 !== 'undefined') {
        resolve(arCompatible());
      } else {
        window.addEventListener('xrloaded', () => resolve(arCompatible()));
      }
    });
  }

  init(canvasObj) {
    return new Promise((resolve, reject) => {
      this.canvas = canvasObj;
      this.running = false; // const camerafeed = document.querySelector('#camerafeed');

      XRExtras.Loading.showLoading({
        onxrloaded: () => {
          console.log('onxrloaded');
          this.events.publish('loaded');
          resolve();
        }
      });
      XR8.FaceController.configure({
        meshGeometry: [XR8.FaceController.MeshGeometry.FACE, XR8.FaceController.MeshGeometry.EYES, XR8.FaceController.MeshGeometry.MOUTH],
        coordinates: {
          mirroredDisplay: true
        }
      });
    });
  } // these modules are always used, so I'm putting them here to keep the rest of the file shorter


  addStandardModules() {
    XR8.addCameraPipelineModule(FullWindowCanvasFactory().pipelineModule());

    if (!hasasked) {
      XR8.addCameraPipelineModules([// Add camera pipeline modules.
      XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
      XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
      XRExtras.RuntimeError.pipelineModule() // Shows an error image on runtime error.
      ]);
      hasasked = true;
    }
  }

  startWorld(settings) {
    if (this.running) this.stop();
    this.running = true;

    if (XR8.XrDevice.isDeviceBrowserCompatible({
      allowedDevices: XR8.XrConfig.device().MOBILE
    })) {
      // for mobile
      XR8.addCameraPipelineModule(XR8.GlTextureRenderer.pipelineModule()); // XR8.addCameraPipelineModule(XRExtras.FullWindowCanvas.pipelineModule());

      for (var m = 0; m < settings.pipelineModules.length; m++) {
        XR8.addCameraPipelineModule(settings.pipelineModules[m]);
      }

      XR8.addCameraPipelineModule(XR8.XrController.pipelineModule());
    } else {
      // for desktop
      // XR8.addCameraPipelineModule(XR8.GlTextureRenderer.pipelineModule());
      for (var m = 0; m < settings.pipelineModules.length; m++) {
        XR8.addCameraPipelineModule(settings.pipelineModules[m]);
      }
    }

    XR8.GlTextureRenderer.configure({
      fragmentSource: settings.cameraFragment || basicCameraFrag // vertexSource : basicSelfieVert

    });
    this.addStandardModules();
    XR8.run({
      canvas: this.canvas,
      cameraConfig: {
        direction: XR8.XrConfig.camera().BACK
      },
      allowedDevices: XR8.XrConfig.device().ANY
    });
  }

  startSelfie(settings) {
    if (this.running) this.stop();
    this.running = true;
    XR8.addCameraPipelineModule(XR8.GlTextureRenderer.pipelineModule());

    for (var m = 0; m < settings.pipelineModules.length; m++) {
      XR8.addCameraPipelineModule(settings.pipelineModules[m]);
    }

    XR8.addCameraPipelineModule(XR8.FaceController.pipelineModule()); // XR8.GlTextureRenderer.configure({
    //   fragmentSource: settings.cameraFragment || basicCameraFrag,
    //   // vertexSource : basicWorldVert
    // });

    this.addStandardModules();
    XR8.run({
      canvas: this.canvas,
      cameraConfig: {
        direction: XR8.XrConfig.camera().FRONT
      },
      allowedDevices: XR8.XrConfig.device().ANY
    });
  }

  stop() {
    this.running = false;
    XR8.stop();
    XR8.clearCameraPipelineModules();
  }

}

export default {
  loadXR8() {
    return XRPipeline.loadXR8();
  },

  getInstance() {
    instance = instance || new XRPipeline();
    return instance;
  }

};