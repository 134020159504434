import { PlaneBufferGeometry, PlaneGeometry, MeshBasicMaterial, Color, Mesh, Matrix4, Vector2, Vector3, Raycaster, SphereGeometry, DoubleSide } from 'three';
import pad from '../utils/pad';
import pubsub from '../utils/pubsub';
import sliceJSON from '../../../../tools/targetSlicer/src/js/slices.json';
import app from '../global';
import basicVert from '../widgets/shaders/basic-vert.glsl';
import { ShaderMaterial } from 'three';
var basicFrag = "\n  varying vec2 vUv;\n\n  void main() {\n    gl_FragColor = vec4(vUv, 0., 1.);\n  }\n";
var events = pubsub.getInstance();
var maxTrackers = 1;
var forceChange = false;

var Pool = function Pool() {
  this.objects = [];

  this.add = obj => {
    this.objects.push(obj);
  };

  this.get = () => {
    if (this.objects.length) return this.objects.shift();
    return false;
  };
};

var threePipelineModule;
var posterContainer;
var trackedTargets = {};
var trackerMeshPool = new Pool();
var raycaster = new Raycaster();
var focalDir = new Vector2(0, 0);
var uvToPixel = new Vector2(sliceJSON.imageInfo.width, sliceJSON.imageInfo.height);
var detailBoxes = new Array(sliceJSON.details.length);
var trackedNum = 0;
var trackingCycles = 0;
var targetSearchIndex = 0;
var targetsSearching = {};
var targetsLoading = false;
var detailMesh;
var posterMesh;
var prevHitIndex = -1;
var xrCamera;
var initialized = false;

var init = pipeline => {
  console.log('POSTER TRACKER MODULE INIT', pipeline);
  threePipelineModule = pipeline;
  posterContainer = pipeline.getWebglPosterGroup();
  var {
    scene,
    camera
  } = pipeline.sceneVars; // Get the 3js scene from XR

  xrCamera = camera; // create quick way to reference slice data by id

  sliceJSON.slicesObj = {};

  for (var i = 0; i < sliceJSON.slices.length; i++) {
    var slice = sliceJSON.slices[i];
    sliceJSON.slicesObj["".concat(slice.id)] = sliceJSON.slices[i];
  } // create the debug meshes and add them to an object pool


  for (var _i = 0; _i < 5; _i++) {
    var trackerMesh = new Mesh(new PlaneBufferGeometry(0.75, 1, 1, 1), new MeshBasicMaterial({
      color: new Color(0xFF0000),
      wireframe: true
    }));
    trackerMesh.visible = false;
    trackerMeshPool.add(trackerMesh);
    scene.add(trackerMesh);
  } // create invisible plane that we will position to match the poster
  // this will be ray cast against to determine where we are looking
  // const posterGeo = new PlaneBufferGeometry(sliceJSON.imageInfo.width, sliceJSON.imageInfo.height, 1, 1);
  // const posterTransform = new Matrix4();
  // posterTransform.setPosition(sliceJSON.imageInfo.width / 2, -sliceJSON.imageInfo.height / 2, 0);
  // posterGeo.applyMatrix4(posterTransform);
  // posterMesh = new Mesh(
  //   posterGeo,
  //   // new MeshBasicMaterial({ color: new Color(0x0000ff), wireframe: false }),
  //   new ShaderMaterial({
  //     vertexShader: basicVert,
  //     fragmentShader: basicFrag,
  //     side: DoubleSide
  //   })
  // );
  // posterMesh.visible = false;
  // posterContainer.add(posterMesh);
  // create a debug mesh that represents all of the scene details
  // detailMesh = new Group();
  // const detailsGeos = [];
  // sliceJSON.details.map((detail) => {


  var detailsGeos = sliceJSON.details.map((detail, index) => {
    var detailGeo = new PlaneBufferGeometry(1, 1, 1, 1);
    var detailTransform = new Matrix4();
    detailTransform.scale(new Vector3(detail.size[0], detail.size[1], 1));
    detailTransform.setPosition(detail.center[0], -detail.center[1], 0);
    detailGeo.applyMatrix(detailTransform);
    detailBoxes[index] = {
      xMin: detail.center[0] - detail.size[0] / 2,
      xMax: detail.center[0] + detail.size[0] / 2,
      yMin: detail.center[1] - detail.size[1] / 2,
      yMax: detail.center[1] + detail.size[1] / 2,
      center: detail.center
    };
    return detailGeo;
  }); // if (app.debug) {
  //   detailMesh = new Mesh(
  //     BufferGeometryUtils.mergeBufferGeometries(detailsGeos),
  //     new MeshBasicMaterial({
  //       color: new Color(0xFFFFFF),
  //       // blending: NoBlending,
  //       // opacity: 0,
  //       wireframe: true
  //     }),
  //   );
  //   detailMesh.visible = true;
  //   posterContainer.add(detailMesh);
  // }
};

var PosterTrackerPipelineModule = pipelineModule => {
  console.log('POSTER TRACKER PIPELINE MODULR'); // Grab a handle to the threejs scene and set the camera position on pipeline startup.

  var onStart = () => {
    console.log('THIS IS POSTER MOD ON START', pipelineModule.sceneVars);

    if (initialized) {
      console.log('was previously initialized');
      Object.keys(trackedTargets).forEach(targetId => {
        console.log('was tracking', targetId);
      });
      forceChange = true;
      targetsSearching = {};
      XR8.XrController.configure({
        imageTargets: []
      });
      return;
    } else {
      init(pipelineModule);
    }

    initialized = true;
    var {
      camera
    } = pipelineModule.sceneVars; // Set the initial camera position relative to the scene we just laid out. This must be at a
    // height greater than y=0.

    camera.position.set(0, 3, 0); // Sync the xr controller's 6DoF position and camera paremeters with our scene.

    XR8.XrController.updateCameraProjectionMatrix({
      origin: camera.position,
      facing: camera.quaternion
    });
  };

  var stop = () => {
    console.log('stopping poster trackers!');
    Object.keys(trackedTargets).forEach(targetId => {
      console.log('was tracking', targetId);
      hideTarget({
        detail: {
          name: targetId
        }
      });
    });
    targetsSearching = {};
    XR8.XrController.configure({
      imageTargets: []
    });
  };

  var reducemaxTrackers = () => {
    if (maxTrackers > 1) {
      maxTrackers--;
      forceChange = true;
    }
  }; // const onProcessCpu = () => ({ posterContainer });


  var onUpdate = () => {
    var minTrackingCycles = 10; // only update image targets if we aren't currently tracking all of our available spots
    // if(!targetsLoading && trackedNum < maxTrackers){

    if (forceChange || !targetsLoading && !trackedNum && trackingCycles > minTrackingCycles) {
      forceChange = false;
      var imageTargets = [];
      var newTargets = false; // start adding more trackers starting from the targetSearchIndex

      for (var i = 0; i < sliceJSON.slices.length && imageTargets.length < maxTrackers; i++) {
        // create id for current search index
        var targetId = sliceJSON.slices[i].id; // `${getSlicePrefix()}${pad(targetSearchIndex, 2)}`;
        // increment our search index

        targetSearchIndex = (targetSearchIndex + 1) % sliceJSON.slices.length; // if not already tracking, we add it

        if (!trackedTargets[targetId]) {
          // console.log(targetSearchIndex, 'adding target: ', targetId);
          imageTargets.push(targetId); // this target was not previously being searched

          if (!targetsSearching[targetId]) newTargets = true;
        }
      } // add the set of image targets to our configuration


      if (newTargets) {
        trackingCycles = 0; // save new set of searches

        targetsSearching = {};
        imageTargets.forEach(targetId => {
          targetsSearching[targetId] = true;
        });
        XR8.XrController.configure({
          imageTargets
        });
      }
    }

    if (!targetsLoading) trackingCycles++; // let hitIndex = -1;

    if (trackedNum) {
      var camDir = xrCamera.getWorldDirection(new Vector3(0, 0, 1));
      var transforms = [];
      Object.keys(trackedTargets).forEach(targetId => {
        // if (app.verbose) console.log('lock to: ', targetId, trackedTargets[targetId].trackerMesh);
        var {
          trackerMesh
        } = trackedTargets[targetId];
        var sliceData = sliceJSON.slicesObj[targetId];
        var vec2Cam = new Vector3().copy(trackerMesh.position).sub(xrCamera.position).normalize();
        trackerMesh.updateMatrix();
        transforms.push({
          position: new Vector3(-sliceData.center[0] + sliceJSON.imageInfo.width / 2, sliceData.center[1] - sliceJSON.imageInfo.height / 2, 0).multiplyScalar(1 / sliceData.size).applyMatrix4(trackerMesh.matrix),
          quaternion: trackerMesh.quaternion,
          scale: Math.abs(trackerMesh.scale.y / sliceData.size),
          // * sliceJSON.imageInfo.height,
          influence: camDir.dot(vec2Cam)
        }); // if (app.verbose) console.log(camDir.dot(vec2Cam));
      });
      transforms.sort((a, b) => b.influence - a.influence);
      threePipelineModule.updateTransform(transforms[0]);
    }
  }; // called when image targets are loading


  var loadingTarget = _ref => {
    var {
      detail
    } = _ref;
    var names = [];
    detail.imageTargets.map(obj => names.push(obj.name)); // console.log("loading Target "+names.join(', '));

    targetsLoading = true;
  }; // called when image targets are ready and scanning


  var scanningTarget = _ref2 => {
    var {
      detail
    } = _ref2;
    var names = [];
    detail.imageTargets.map(obj => names.push(obj.name)); // console.log(`scanning for Target ${names.join(', ')}`);

    targetsLoading = false;
  };

  var updateTarget = _ref3 => {
    var {
      detail
    } = _ref3;
    // console.log('UPDATE target', detail.name, detail.position.x, detail.position.y, detail.position.z);
    if (!trackedTargets[detail.name]) return;
    var {
      trackerMesh
    } = trackedTargets[detail.name];
    if (app.debug) trackerMesh.visible = true;
    trackerMesh.position.copy(detail.position);
    trackerMesh.quaternion.copy(detail.rotation);
    trackerMesh.scale.set(detail.scale, detail.scale, detail.scale);
  };

  var showTarget = _ref4 => {
    var {
      detail
    } = _ref4;

    // console.log('SHOW target');
    if (!trackedTargets[detail.name]) {
      trackedTargets[detail.name] = {
        id: detail.name,
        trackerMesh: trackerMeshPool.get() // retrieve from pool

      };
      trackedNum++;
    } //


    events.publish('foundposter');
    updateTarget({
      detail
    });
  };

  var hideTarget = _ref5 => {
    var {
      detail
    } = _ref5;

    // console.log('HIDE target', detail);
    if (trackedTargets[detail.name]) {
      // console.log('HIDE target', detail.name);
      var {
        trackerMesh
      } = trackedTargets[detail.name];
      trackerMesh.visible = false;
      trackerMeshPool.add(trackerMesh); // return to pool

      trackedTargets[detail.name].trackerMesh = null; // remove reference

      delete trackedTargets[detail.name]; // remove from trackedTarget object

      trackedNum--;
    }

    events.publish('lostposter');
  };

  var getPt = (x, y) => {
    posterContainer.updateMatrix();
    posterContainer.updateMatrixWorld();
    return {
      position: posterContainer.localToWorld(new Vector3(x, -y, 0)),
      quaternion: posterContainer.quaternion.clone(),
      scale: posterContainer.scale.clone()
    };
  };

  return {
    name: 'posterTracker',
    onStart,
    onUpdate,
    reducemaxTrackers,
    getPt,
    stop,
    events,
    listeners: [{
      event: 'reality.imageloading',
      process: loadingTarget
    }, {
      event: 'reality.imagescanning',
      process: scanningTarget
    }, {
      event: 'reality.imagefound',
      process: showTarget
    }, {
      event: 'reality.imageupdated',
      process: updateTarget
    }, {
      event: 'reality.imagelost',
      process: hideTarget
    }]
  };
};

export { PosterTrackerPipelineModule as default };