/* globals XR8:readonly */
import app from '../global';
var fullWindowCanvas = null;

var FullWindowCanvasFactory = () => {
  if (fullWindowCanvas == null) {
    fullWindowCanvas = create();
  }

  return fullWindowCanvas;
};

function create() {
  var canvas_ = null;
  var vsize_ = {};
  var orientation_ = 0;
  var originalBodyStyleSubset_ = {};
  var originalHtmlStyleSubset_ = {};
  var canvasStyle_ = {
    width: '100%',
    height: '100%',
    margin: '0px',
    padding: '0px',
    border: '0px',
    display: 'block'
  };
  var bodyStyle_ = {
    width: '100%',
    height: '100%',
    margin: '0px',
    padding: '0px',
    border: '0px'
  };

  var isCompatibleMobile = () => // eslint-disable-next-line implicit-arrow-linebreak
  XR8.XrDevice.isDeviceBrowserCompatible({
    allowedDevices: XR8.XrConfig.device().MOBILE
  }) && !XR8.XrDevice.deviceEstimate().model.toLowerCase().includes('ipad'); // Update the size of the camera feed canvas to fill the screen.


  var fillScreenWithCanvas = () => {
    if (!canvas_) {
      return;
    } // Get the pixels of the browser window.


    var uww = window.innerWidth;
    var uwh = window.innerHeight;
    var ww = uww * devicePixelRatio;
    var wh = uwh * devicePixelRatio; // Wait for orientation change to take effect before handling resize on mobile phones only.

    var displayOrientationMismatch = (orientation_ == 0 || orientation_ == 180) && ww > wh || (orientation_ == 90 || orientation_ == -90) && wh > ww;

    if (displayOrientationMismatch && isCompatibleMobile()) {
      window.requestAnimationFrame(fillScreenWithCanvas);
      return;
    } // Compute the portrait-orientation aspect ratio of the browser window.


    var ph = Math.max(ww, wh);
    var pw = Math.min(ww, wh);
    var pa = ph / pw; // Compute the portrait-orientation dimensions of the video.

    var pvh = Math.max(vsize_.w, vsize_.h);
    var pvw = Math.min(vsize_.w, vsize_.h); // Compute the cropped dimensions of a video that fills the screen, assuming that width is
    // cropped.

    var ch = pvh;
    var cw = Math.round(pvh / pa); // Figure out if we should have cropped from the top, and if so, compute a new cropped video
    // dimension.

    if (cw > pvw) {
      cw = pvw;
      ch = Math.round(pvw * pa);
    } // If the video has more pixels than the screen, set the canvas size to the screen pixel
    // resolution.


    if (cw > pw || ch > ph || app.desktop) {
      cw = pw;
      ch = ph;
    } // Switch back to a landscape aspect ratio if required.


    if (ww > wh) {
      var tmp = cw;
      cw = ch;
      ch = tmp;
    } // Set the canvas geometry to the new window size.


    Object.assign(canvas_.style, canvasStyle_);
    canvas_.width = cw;
    canvas_.height = ch; // on iOS, rotating from portrait to landscape back to portrait can lead to a situation where
    // address bar is hidden and the content doesn't fill the screen. Scroll back up to the top in
    // this case. In chrome this has no effect. We need to scroll to something that's not our
    // scroll position, so scroll to 0 or 1 depending on the current position.

    setTimeout(() => window.scrollTo(0, (window.scrollY + 1) % 2), 300);
  };

  var updateVideoSize = _ref => {
    var {
      videoWidth,
      videoHeight
    } = _ref;
    vsize_.w = videoWidth;
    vsize_.h = videoHeight;
  };

  var onWindowResize = () => {
    if (isCompatibleMobile()) {
      return;
    }

    fillScreenWithCanvas();
  };

  var onVideoSizeChange = _ref2 => {
    var {
      videoWidth,
      videoHeight
    } = _ref2;
    updateVideoSize({
      videoWidth,
      videoHeight
    });
    fillScreenWithCanvas();
  };

  var onCameraStatusChange = _ref3 => {
    var {
      status,
      video
    } = _ref3;

    if (status !== 'hasVideo') {
      return;
    }

    updateVideoSize(video);
  };

  var onCanvasSizeChange = () => {
    fillScreenWithCanvas();
  };

  var onUpdate = () => {
    if (canvas_.style.width === canvasStyle_.width && canvas_.style.height === canvasStyle_.height) {
      return;
    }

    fillScreenWithCanvas();
  };

  var onAttach = _ref4 => {
    var {
      canvas,
      orientation,
      videoWidth,
      videoHeight
    } = _ref4;
    canvas_ = canvas;
    orientation_ = orientation;

    if (XR8.XrDevice.deviceEstimate().os === 'iOS') {
      // Save styles that we modify in unusual ways so we can reset them to their original state.
      var computedBodyStyle = getComputedStyle(document.body);
      originalBodyStyleSubset_.backgroundColor = computedBodyStyle.getPropertyValue('background-color');
      originalBodyStyleSubset_.overflowY = computedBodyStyle.getPropertyValue('overflow-y');
      originalHtmlStyleSubset_.overflow = getComputedStyle(document.documentElement).getPropertyValue('overflow'); // Set black / white background color on iOS devices depending on dark / light mode.

      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        bodyStyle_.backgroundColor = 'black';
      } else {
        bodyStyle_.backgroundColor = 'white';
      } // Prevent address bar hiding on scroll on iOS (https://stackoverflow.com/a/33953987/4979029).


      bodyStyle_.overflowY = 'scroll';
      Object.assign(document.documentElement.style, {
        overflow: 'hidden'
      });
    }

    Object.assign(document.body.style, bodyStyle_);
    document.body.appendChild(canvas_);
    window.addEventListener('resize', onWindowResize);
    updateVideoSize({
      videoWidth,
      videoHeight
    });
    fillScreenWithCanvas();
  };

  var onDetach = () => {
    // Reset styles that we cached in `onAttach()`.
    Object.assign(document.body.style, originalBodyStyleSubset_);
    Object.assign(document.documentElement.style, originalHtmlStyleSubset_);
    canvas_ = null;
    orientation_ = 0;
    delete vsize_.w;
    delete vsize_.h;
    window.removeEventListener('resize', onWindowResize);
  };

  var onDeviceOrientationChange = _ref5 => {
    var {
      orientation
    } = _ref5;
    orientation_ = orientation;
    fillScreenWithCanvas();
  };

  var pipelineModule = () => ({
    name: 'fullwindowcanvas',
    onAttach,
    onDetach,
    onCameraStatusChange,
    onDeviceOrientationChange,
    onVideoSizeChange,
    onCanvasSizeChange,
    onUpdate
  });

  return {
    // Creates a camera pipeline module that, when installed, keeps the canvas specified on
    // XR8.run() to cover the whole window.
    pipelineModule
  };
}

export default FullWindowCanvasFactory;