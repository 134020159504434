function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Scene, PerspectiveCamera, WebGLRenderer, Vector3, Quaternion, Texture } from 'three';
import pubsub from '../utils/pubsub';
var renderer;

class ThreeJSPipelineModule {
  constructor(_name) {
    _defineProperty(this, "name", 'customthreejs');

    if (_name) this.name = _name;
    this.engaged = false;
    this.events = pubsub.getInstance();
    this.currTime = Date.now();
    this.runTime = 0;
    this.prevTime = Date.now(); // 8THWALL loses the context so we have to bind all of the functions that 
    // their pipeline calls to this instance's scope

    this.onStart = this._onStart.bind(this);
    this.onAttach = this._onAttach.bind(this);
    this.onDetach = this._onDetach.bind(this);
    this.onException = this._onException.bind(this);
    this.onUpdate = this._onUpdate.bind(this);
    this.onCanvasSizeChange = this._onCanvasSizeChange.bind(this);
    this.onRender = this._onRender.bind(this);
    this.listeners = [];
  }

  engage(_ref) {
    var {
      canvas,
      canvasWidth,
      canvasHeight,
      GLctx
    } = _ref;

    if (this.engaged) {
      return;
    } // console.log('/////////////////////////// ENGAGE ////////////////////////', this.name);


    var scene = new Scene();
    var camera = new PerspectiveCamera(50.0,
    /* initial field of view; will get set based on device info later. */
    canvasWidth / canvasHeight, 0.01, 100.0);
    scene.add(camera);
    camera.position.set(0, 1.7, 0);

    if (!renderer) {
      renderer = new WebGLRenderer({
        canvas,
        context: GLctx,
        alpha: false,
        antialias: true
      });
      renderer.autoClear = false;
      renderer.setSize(canvasWidth, canvasHeight);
    }

    this.sceneVars = {
      scene,
      camera,
      renderer,
      cameraDestPos: new Vector3(),
      cameraDestQuat: new Quaternion(),
      cameraTex: new Texture()
    };
    this.engaged = true;
    XR8.XrController.updateCameraProjectionMatrix({
      origin: camera.position,
      facing: camera.quaternion
    });
    this.events.publish('onStart');
  }

  _onStart(args) {
    console.log('onStart!');
    if (this.engaged) return;
    this.engage(args);
    this.onCanvasSizeChange(args);
  }

  _onAttach(args) {
    console.log('_onAttach!');
    if (this.engaged) return;
    this.engage(args);
    this.onCanvasSizeChange(args);
    if (callback) callback(); // this.events.publish('onAttach');
  }

  _onDetach() {}

  _onException(err) {
    console.log('onException', err);
  }

  _onUpdate(_ref2) {
    var {
      frameStartResult,
      processCpuResult,
      processGpuResult
    } = _ref2;
    var realitySource = processCpuResult.reality || processCpuResult.facecontroller;
    if (!realitySource) return;
    var {
      rotation,
      position,
      intrinsics
    } = realitySource;
    var {
      camera,
      cameraDestPos,
      cameraDestQuat,
      cameraTex,
      renderer
    } = this.sceneVars;
    var {
      cameraTexture
    } = frameStartResult;
    var texProps = renderer.properties.get(cameraTex);
    texProps.__webglTexture = cameraTexture; /////

    for (var i = 0; i < 16; i++) {
      camera.projectionMatrix.elements[i] = intrinsics[i];
    } // Fix for broken raycasting in r103 and higher. Related to:
    //   https://github.com/mrdoob/three.js/pull/15996
    // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
    // the inverse


    if (camera.projectionMatrixInverse) {
      if (camera.projectionMatrixInverse.invert) {
        // THREE 123 preferred version
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
      } else {
        // Backwards compatible version
        camera.projectionMatrixInverse.getInverse(camera.projectionMatrix);
      }
    }

    this.events.publish('onUpdate', {
      rotation,
      position,
      intrinsics
    });

    if (rotation) {
      cameraDestQuat.copy(rotation); // camera.setRotationFromQuaternion(rotation)
    }

    if (position) {
      cameraDestPos.set(position.x, position.y, position.z); // camera.position.set(position.x, position.y, position.z)
    }
  }

  _onCanvasSizeChange(_ref3) {
    var {
      canvasWidth,
      canvasHeight
    } = _ref3;

    if (!this.engaged) {
      return;
    }

    var {
      renderer,
      camera
    } = this.sceneVars;
    renderer.setSize(canvasWidth, canvasHeight); // console.log(canvasHeight, canvasWidth)

    camera.aspect = canvasWidth / canvasHeight;
    camera.updateProjectionMatrix();
  }

  _onRender() {
    var {
      scene,
      renderer,
      camera,
      cameraDestPos,
      cameraDestQuat
    } = this.sceneVars;
    this.currTime = Date.now();
    var elapsed = this.currTime - this.prevTime;
    this.runTime += elapsed / 1000;
    this.prevTime = this.currTime;
    camera.quaternion.slerp(cameraDestQuat, 0.25);
    camera.position.lerp(cameraDestPos, 0.25);
    renderer.clearDepth();
    if (!this.paused) this.events.publish('render');
    renderer.render(scene, camera);
  }

  getSceneVars() {
    return this.sceneVars;
  }

}

export default ThreeJSPipelineModule;