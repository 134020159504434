/* eslint-disable class-methods-use-this */

/* eslint-disable max-len */
import { gsap } from 'gsap/all';

class LoaderUI {
  constructor() {
    this.id = 'LoaderUI';
  }

  init(callback) {
    callback();
  }

  goOut() {
    return new Promise(resolve => {
      if (this.elem) this.elem.classList.add('hide');
      resolve();
    });
  }

  bringIn() {
    return new Promise(resolve => {
      console.log('BRING IN LOADER');
      if (this.elem) this.elem.classList.add('show');
      resolve();
    });
  }

  onProgress(perc) {
    // if (this.prog_el) {
    //   this.prog_el.style.transform = `scaleX(${perc})`;
    // } else {
    //   this.prog_el_fallback.style.width = `${Math.round(perc * 100)}%`;
    // }
    // this.percentage.textContent = `${Math.round(perc * 100)}%`;
    return perc >= 1;
  }

  resize() {} // this.elem = document.getElementById('loader');
  // this.percentage = document.getElementById('percentage');


}

export default LoaderUI;