import LazyLoad from 'vanilla-lazyload';
import * as dat from 'dat.gui';
import ArrayExecutor from './app/utils/ArrayExecutor';
import Navigation from './app/utils/Navigation';
import Menu from './app/widgets/Menu';
import Footer from './app/widgets/Footer';
import AR from './app/sections/AR';
import GiftShop from './app/sections/GiftShop';
import Selfie from './app/sections/Selfie';
import Preloader from './app/utils/Preloader';
import SectionLoader from './app/utils/SectionLoader';
import XRPipeline from './app/widgets/XRPipeline';
import { Howler } from 'howler'; // import WorldScene from './app/widgets/WorldScene';
// import SelfieScene from './app/widgets/SelfieScene';

import sfxmanager from './app/utils/sfx';
import findAncestor from './app/utils/findAncestor';
import app from './app/global';
import worldCameraFrag from './app/widgets/shaders/worldCamera-frag.glsl';
import selfieCameraFrag from './app/widgets/shaders/selfieCamera-frag.glsl';
import { Vector3 } from 'three';
import UWOMTLoaderUI from './app/widgets/UWOMTLoaderUI';
var sfx = typeof sfxmanager === 'undefined' ? false : sfxmanager;
var sections = {
  AR,
  GiftShop,
  Selfie
};
app.verbose = true;
app.debug = window.location.hash.includes('debug');
app.qr = window.location.hash.includes('qr') || window.location.host.match(/^cageaissanceqr\.oblio/);
app.debug_shortcut = window.location.hash.includes('#shortcut');
app.noar = window.location.hash.includes('#noar');
app.site_path = window.site_path;
app.site_url = window.location.origin;
if (app.debug) (function () {
  var script = document.createElement('script');

  script.onload = function () {
    var stats = new Stats();
    document.body.appendChild(stats.dom);
    requestAnimationFrame(function loop() {
      stats.update();
      requestAnimationFrame(loop);
    });
  };

  script.src = '//mrdoob.github.io/stats.js/build/stats.min.js';
  document.head.appendChild(script);
})();
var arrayExecutor = ArrayExecutor(null, 'Main');
var navigation = Navigation.getInstance();
var sectionLoader = SectionLoader.getInstance();
var preloader = Preloader.getInstance();
var loaderUI = new UWOMTLoaderUI();
loaderUI.init().then(() => {
  preloader.setLoader(loaderUI);
});

function handleResize() {
  if (app.mainMenu) app.mainMenu.resize();
  app.sections[navigation.currentSection].resize();
  app.SCREEN_WIDTH = window.innerWidth;
  app.SCREEN_HEIGHT = window.innerHeight;
}

function initSoundButton() {
  // Set the name of the hidden property and the change event for visibility
  var visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    visibilityChange = 'visibilitychange';
  } else if (typeof document.mozHidden !== 'undefined') {
    visibilityChange = 'mozvisibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    visibilityChange = 'webkitvisibilitychange';
  }
  /**
   * Mute audio when user opens a different browser tab, unmute when returning
   * to site if audio was not previously muted by clicking the sound button
   */


  document.addEventListener(visibilityChange, e => {
    if (!sfx) return;

    if (e.target.hidden === true) {
      sfx.mute(true);
      app.xrpipeline.paused = true;
    } else if (e.target.hidden === false) {
      if (sound_button.classList.contains('on')) sfx.mute(false);
      app.xrpipeline.paused = false;
    } else {
      console.log('unknown page visibility status');
    }
  });
  var sound_button = document.getElementById('soundButton');
  sound_button.classList.remove('off');
  sound_button.classList.add('on');
  sound_button.addEventListener('click', () => {
    var toggleMute = true;

    if (sound_button.classList.contains('on')) {
      sound_button.classList.remove('on');
      sound_button.classList.add('off');
      if (toggleMute && sfx) sfx.mute(true);
    } else {
      sound_button.classList.remove('off');
      sound_button.classList.add('on');
      if (toggleMute && sfx) sfx.mute(false);
    }
  });
}

function siteIsIn() {
  console.log('SITE IS IN');
  navigation.changeOrder = ['section_hide_prev', 'section_shutdown_prev', startPipeline, 'load', 'section_add_next', 'section_init_next', 'section_startup_next', 'section_show_next'];
  document.body.classList.add('sitein');
  if (app.mainMenu) app.mainMenu.show();
  if (app.footer) app.footer.show();
  handleResize();
  initSoundButton();
}

function setupSectionObjects(callback) {
  app.sections = {};
  Object.keys(sections).reduce((o, section) => {
    var obj = o;
    obj[section.toLowerCase()] = sections[section].getInstance();
    sectionLoader.addSection(section, {
      files: []
    });
    return obj;
  }, app.sections);
  sectionLoader.addSection('main', {});
  if (callback) callback();
}

function setupMenu(callback) {
  console.log('SET UP MENU');

  if (typeof Menu !== 'undefined') {
    app.mainMenu = Menu.getNew();
    app.mainMenu.init();
  }

  var imgLoaded = element => {
    element.classList.add('loaded');
  };

  app.lazyloader = new LazyLoad({
    unobserve_entered: true,
    // callback_enter: showCover,
    callback_loaded: imgLoaded,
    thresholds: '100%'
  });
  callback();
}

function setupFooter(callback) {
  console.log('SET UP FOOTER');

  if (typeof Footer !== 'undefined') {
    app.footer = Footer.getInstance();
    app.footer.init();
  }

  callback();
}

function startPipeline(currSection, prevSection, callback) {
  console.log('START PIPELINE', app.sections, currSection, prevSection);

  if (app.sections[currSection.toLowerCase()].startPipeline) {
    app.sections[currSection.toLowerCase()].startPipeline();
  }

  callback();
}

function init() {
  setupSectionObjects();
  navigation.currentSection = document.body.dataset.section; // alert(navigation.currentSection);

  navigation.forceChange = true;
  navigation.changeOrder = [startPipeline, 'load', 'section_add_next', 'section_init_next', 'section_startup_next', 'section_show_next'];
  var ext = '';

  if (Howler.codecs('m4a')) {
    ext = 'm4a';
  } else if (Howler.codecs('mp3')) {
    ext = 'mp3';
  } else if (Howler.codecs('ogg')) {
    ext = 'ogg';
  } else if (Howler.codecs('ac3')) {
    ext = 'ac3';
  }

  console.log('EXT', ext);
  var files = ["".concat(app.site_path, "assets/images/textures/room_walls_floor_color.jpg"), "".concat(app.site_path, "assets/images/textures/Gun_BaseColor.jpg"), "".concat(app.site_path, "assets/images/textures/Gun_Metallic.jpg"), "".concat(app.site_path, "assets/images/textures/Gun_Normal.jpg"), "".concat(app.site_path, "assets/images/textures/Gun_Roughness.jpg"), "".concat(app.site_path, "assets/images/textures/pillow_pillow_main_color.jpg"), "".concat(app.site_path, "assets/images/textures/nic_color.jpg"), "".concat(app.site_path, "assets/images/textures/room_walls_floor_color.jpg"), "".concat(app.site_path, "assets/images/textures/poster.jpg"), "".concat(app.site_path, "assets/images/textures/props_color.jpg"), "".concat(app.site_path, "assets/images/textures/art_color.jpg"), "".concat(app.site_path, "assets/images/textures/hotspot.png"), "".concat(app.site_path, "assets/images/textures/hotspot-divine.png"), "".concat(app.site_path, "assets/images/textures/question.png"), "".concat(app.site_path, "assets/images/textures/statue_01_color.jpg"), "".concat(app.site_path, "assets/images/textures/statue_02_color.jpg"), "".concat(app.site_path, "assets/images/textures/statue_03_color.jpg"), "".concat(app.site_path, "assets/models/museum2.glb"), "".concat(app.site_path, "assets/audio/dialogue.").concat(ext), "".concat(app.site_path, "assets/audio/DX_IM_SO_HAPPY.").concat(ext), "".concat(app.site_path, "assets/audio/siteFX.").concat(ext), "".concat(app.site_path, "assets/audio/sx_amb_drone_loop_r1.").concat(ext)];

  if (files.length > 0) {
    sectionLoader.addFiles('main', files);
  }

  navigation.loadQueue('main', navigation.currentSection);
  var functionArr = [];
  Array.prototype.push.apply(functionArr, [// { fn: navigation.load, scope: navigation, vars: null },
  {
    fn: setupMenu,
    vars: null
  }, {
    fn: setupFooter,
    vars: null
  }, {
    fn: navigation.changeSection,
    scope: navigation,
    vars: [window.location.pathname]
  }, {
    fn: siteIsIn
  }]);
  arrayExecutor.execute(functionArr);
}

function Main() {
  app.settings = app.settings || {};
  app.sections = {};
  app.settings.homeSection = 'ar';
  sfx.loadFX('siteFX');
  sfx.loadFX('dialogue');
  sfx.loadSound('DX_IM_SO_HAPPY', false);
  document.documentElement.classList.add('no-touch');
  window.addEventListener('touchstart', function setHasTouch() {
    document.documentElement.classList.remove('no-touch');
    document.documentElement.classList.add('touch');
    if (app.mainMenu && app.mainMenu.setTouch) app.mainMenu.setTouch();
    window.removeEventListener('touchstart', setHasTouch);
  }, false);
  window.addEventListener('resize', handleResize);
  window.addEventListener('onorientationchange', handleResize);
  XRPipeline.loadXR8().then(isAR => {
    console.log('XR8LOADED');

    if (!isAR) {
      // set app.qr to false for cases where user
      // visits the qr version from a desktop
      if (app.qr) app.qr = false;
      app.desktop = true;
      document.documentElement.classList.add('desktop');
    }

    app.xrpipeline = XRPipeline.getInstance();
    app.xrpipeline.events.subscribe('loaded', () => {
      console.log('LOADED'); // const load_wrapper = document.querySelector('#loadImageContainer');
      // const loader = document.createElement('div');
      // loader.classList.add('loader');
      // loader.innerHTML = '<div></div><div></div>';
      // load_wrapper.appendChild(loader);
    });
    app.xrpipeline.init(document.querySelector('#camerafeed')).then(() => {
      init(); // const worldScene = WorldScene.getInstance();
      // app.xrpipeline.startWorld(worldScene.settings);
      // const selfieScene = SelfieScene.getInstance();
      // app.xrpipeline.startWorld(selfieScene.settings);
      // const addCameraSwitchingButtons = () => {
      //   const gui = new dat.GUI({
      //     closed: false
      //   });
      //   const controls = {
      //     startWorld: () => { app.xrpipeline.startWorld(worldScene.settings); },
      //     startSelfie: () => { app.xrpipeline.startSelfie(selfieScene.settings); }
      //   };
      //   gui.add(controls, 'startWorld');
      //   gui.add(controls, 'startSelfie');
      // };
      // addCameraSwitchingButtons();
    });
  });
}

Main.prototype.resize = handleResize;
app.main = new Main();
window.addEventListener('beforeunload', () => {
  window.scroll(0, 0); // alert('BEFORE UNLOAD');
});