export default {
  "src": ["/assets/audio/siteFX.ogg", "/assets/audio/siteFX.m4a", "/assets/audio/siteFX.mp3", "/assets/audio/siteFX.ac3"],
  "sprite": {
    "sx_close_bust_r1": [0, 1517.7551020408164],
    "sx_close_poster_r1": [3000, 1111.3151927437643],
    "sx_enter_click_r1": [6000, 4947.7324263038545],
    "sx_kaleidoscope_close_r1": [12000, 1858.4126984126979],
    "sx_kaleidoscope_open_r1": [15000, 1744.8752834467136],
    "sx_menu_wipe_down_r1": [18000, 2122.5396825396815],
    "sx_menu_wipe_up_r1": [22000, 1672.040816326529],
    "sx_touch_bust_r1": [25000, 2664.897959183673],
    "sx_touch_poster_r1": [29000, 1558.0045351473934]
  }
};