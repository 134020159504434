import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import pubsub from '../utils/pubsub';
import app from '../global';
import Navigation from '../utils/Navigation';
var navigation = Navigation.getInstance();
var myName = 'GiftShop';
var sectionLoader = SectionLoader.getInstance();
var instance;

function showSuccess() {
  var wrapper = document.querySelector('#giftshop');
  wrapper.classList.add('success');
}

function showGeneralError(error_message) {
  var general_error = document.querySelector('#general_error');
  general_error.textContent = error_message;
  general_error.classList.add('show');
}

function hideGeneralError() {
  var general_error = document.querySelector('#general_error');
  general_error.classList.remove('show');
}

function reset() {
  var wrapper = document.querySelector('#giftshop');
  wrapper.classList.remove('success');
  var general_error = document.querySelector('#general_error');
  general_error.classList.remove('show');
  var name_field = document.querySelector('#mce-FNAME');
  name_field.value = '';
  var email_field = document.querySelector('#mce-EMAIL');
  email_field.value = '';
  var terms_checkbox = document.querySelector('#terms_checkbox');
  terms_checkbox.checked = false;
  var rules_checkbox = document.querySelector('#rules_checkbox');
  rules_checkbox.checked = false;
}

function getSerializedFormData() {
  // Get the form
  var form = document.querySelector('#giftshop form'); // Get all field data from the form

  var data = new FormData(form);
  data.delete('terms_checkbox');
  data.delete('rules_checkbox'); // Convert to a query string

  var queryString = new URLSearchParams(data).toString();
  return queryString;
}

function validateForm() {
  return new Promise(resolve => {
    hideGeneralError();
    var name_field = document.querySelector('#mce-FNAME');
    var email_field = document.querySelector('#mce-EMAIL');
    var terms_checkbox = document.querySelector('#terms_checkbox');
    var rules_checkbox = document.querySelector('#rules_checkbox');
    var valid = true;

    if (!name_field.checkValidity()) {
      valid = false;
      name_field.parentElement.classList.add('invalid');
    } else {
      name_field.parentElement.classList.remove('invalid');
    }

    if (!email_field.checkValidity()) {
      valid = false;
      email_field.parentElement.classList.add('invalid');
    } else {
      email_field.parentElement.classList.remove('invalid');
    }

    if (!terms_checkbox.checkValidity()) {
      valid = false;
      terms_checkbox.parentElement.parentElement.classList.add('invalid');
    } else {
      terms_checkbox.parentElement.parentElement.classList.remove('invalid');
    }

    if (!rules_checkbox.checkValidity()) {
      valid = false;
      rules_checkbox.parentElement.parentElement.classList.add('invalid');
    } else {
      rules_checkbox.parentElement.parentElement.classList.remove('invalid');
    }

    resolve(valid);
  });
}

class GiftShop extends Section {
  constructor() {
    super(myName);
  } // eslint-disable-next-line class-methods-use-this


  prepareLoad() {
    var files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  init(callback) {
    if (this.initialized) {
      if (callback) callback();
      return;
    }

    this.events = pubsub.getInstance();
    super.init();
    this.resize();
    this.elements.sectionWrapper.querySelector('.back_to_museum').addEventListener('click', e => {
      e.preventDefault();
      navigation.changeSection('ar');
    });
    this.elements.sectionWrapper.querySelector('input[type="submit"]').addEventListener('click', e => {
      e.preventDefault();
      validateForm().then(isValid => {
        if (isValid) {
          var querystring = getSerializedFormData();
          var scripttag = document.createElement('script');
          scripttag.src = "https://oblio.us3.list-manage.com/subscribe/post-json?".concat(querystring, "&c=giftshopcallback");
          document.body.appendChild(scripttag);
          document.querySelector('#giftshop').classList.add('processing');
        } else {
          console.log('NOT VALID');
        }
      });
    });

    window.giftshopcallback = data => {
      switch (data.result) {
        case 'success':
          showSuccess();
          break;

        case 'error':
          showGeneralError(data.msg);
          break;

        default:
          console.log('not success or error', data);
          showSuccess();
      }

      document.querySelector('#giftshop').classList.remove('processing');
    };

    if (callback) callback();
  }

  show(callback) {
    super.show(callback);
    reset();
    console.log('SHOW GIFT SHOP');
  }

  resize() {
    this.winWidth = window.innerWidth;
    this.winHeight = window.innerHeight;
  }

}

export default {
  getInstance() {
    instance = instance || new GiftShop();
    return instance;
  }

};