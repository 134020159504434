/* eslint-disable no-mixed-operators */

/* eslint-disable prefer-destructuring */

/* eslint-disable class-methods-use-this */
import * as dat from 'dat.gui';
import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import pubsub from '../utils/pubsub';
import app from '../global';
import Navigation from '../utils/Navigation';
import SelfieScene from '../widgets/SelfieScene';
import sfx from '../utils/sfx';
var myName = 'Selfie';
var sectionLoader = SectionLoader.getInstance();
var navigation = Navigation.getInstance();
var instance;

class Selfie extends Section {
  constructor() {
    super(myName);
  }

  initDatGui() {
    var gui = new dat.GUI({
      closed: true
    });
  } // eslint-disable-next-line class-methods-use-this


  prepareLoad() {
    var files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  startPipeline(callback) {
    var selfieScene = SelfieScene.getInstance();
    selfieScene.reset();
    app.xrpipeline.startSelfie(selfieScene.settings);
    if (callback) callback();
  }

  init(callback) {
    console.log('INIT Selfie');

    if (this.initialized) {
      if (callback) callback();
      return;
    }

    if (app.debug || app.noar) this.initDatGui();
    this.events = pubsub.getInstance();
    super.init(callback);
    this.resize();
    this.elements.sectionWrapper.querySelector('.back_to_museum').addEventListener('click', e => {
      navigation.changeSection('ar');
    });
    var selfieScene = SelfieScene.getInstance();
    selfieScene.kaliedoscope.events.subscribe('bowaccepted', () => {
      this.elements.sectionWrapper.classList.remove('showinstructions');
    });
  }

  update(data) {}

  show(callback) {
    super.show();
    this.elements.sectionWrapper.style.opacity = 1;
    var cameraswitcher = document.querySelector('#cameraswitcher');
    cameraswitcher.classList.add('selfie');
    console.log('SHOW Selfie');
    this.elements.sectionWrapper.classList.add('showintro');
    var selfieScene = SelfieScene.getInstance();
    selfieScene.kaliedoscope.show();
    window.setTimeout(() => {
      this.elements.sectionWrapper.classList.add('hideintro');
      this.elements.sectionWrapper.classList.add('showinstructions');
      this.elements.sectionWrapper.querySelector('.back_to_museum').classList.add('show');
      window.setTimeout(() => {
        selfieScene.kaliedoscope.watchBow();
      }, 500);
      callback();
    }, 3000);
  }

  hide(callback) {
    super.hide();
    var selfieScene = SelfieScene.getInstance();
    selfieScene.kaliedoscope.hide();
    this.elements.sectionWrapper.classList.remove('showinstructions');
    this.elements.sectionWrapper.querySelector('.back_to_museum').classList.remove('show');
    sfx.stopSound('mx_divine_inspo_psych_loop_r1');
    callback();
  }

  shutdown(callback) {
    super.shutdown();
    this.elements.sectionWrapper.style.opacity = 0;
    this.elements.sectionWrapper.classList.remove('showintro', 'hideintro');
    callback();
  }

  resize() {
    this.winWidth = window.innerWidth;
    this.winHeight = window.innerHeight;
  }

}

export default {
  getInstance() {
    instance = instance || new Selfie();
    return instance;
  }

};