export default {
  "src": ["/assets/audio/dialogue.ogg", "/assets/audio/dialogue.m4a", "/assets/audio/dialogue.mp3", "/assets/audio/dialogue.ac3"],
  "sprite": {
    "DX_AN ENORMOUS HEAD": [0, 2414.1496598639455],
    "DX_DIVINE INSPIRATION": [4000, 1340.612244897959],
    "DX_HE WAS INCREDIBLE": [7000, 957.1655328798183],
    "DX_HOW MUCH DID YOU PAY FOR THIS": [9000, 4520.498866213153],
    "DX_I AM JAVI": [15000, 928.1179138321995],
    "DX_I AM SORRY IF IT IS WEIRD": [17000, 2561.7687074829937],
    "DX_I CANT DRIVE ON ACID": [21000, 3741.9274376417243],
    "DX_I HAVE A VERY BIG HEAD": [26000, 1284.7165532879822],
    "DX_IM HAPPY IM HEALTHY": [29000, 3172.993197278913],
    "DX_IM SO HAPPY THAT YOU ARE HERE": [34000, 2096.6893424036284],
    "DX_IS IT TOO MUCH": [38000, 1407.5736961451212],
    "DX_IS THIS SUPPOSED TO BE ME ITS GROTESQUE": [41000, 8008.004535147393],
    "DX_IS THIS SUPPOSED TO BE ME": [51000, 1722.0634920634907],
    "DX_ITS A GOOD COLLECTION": [54000, 3631.496598639458],
    "DX_NIC CAGE": [59000, 869.2063492063511],
    "DX_NO ITS NOT CREEPY": [61000, 1775.3741496598607],
    "DX_OPEN THE DOOR": [64000, 1873.3560090702922],
    "DX_THIS IS NOT FOR SALE": [67000, 2043.8548752834436],
    "DX_TRIPPY": [71000, 1509.7505668934305],
    "DX_WE HAVE TO OPEN OUR MINDS": [74000, 7365.986394557823],
    "DX_YOU HAVE A GIFT": [83000, 2381.065759637181],
    "DX_YOU WANT ME TO LET YOU IN THERE": [87000, 1824.0816326530621],
    "DX_YOUR NIC FUCKING CAGE": [90000, 15223.560090702946]
  }
};